<div class="chat-info">
  <span class="chat-info__name"> {{ chat?.name }} </span>
  <ng-container *ngIf="chat?.conversation_type === ConversationType.direct">
    <span *ngIf="!isThread" class="chat-info__role"> {{ chat?.direct_to.role_value }} </span>

    <ng-container *ngIf="!isThread">
      <span *ngIf="chat?.direct_to.status === OnlineStatus.Offline && chat?.direct_to.was_last_online" class="chat-info__last-online">
        {{ 'messageCenter.lastSeen' | translate }} {{ getTimeAgo(chat?.direct_to.was_last_online) }}
      </span>

      <span class="chat-info__online" *ngIf="chat?.direct_to.status === OnlineStatus.Online">
        {{ 'messageCenter.online' | translate }}
      </span>
    </ng-container>
  </ng-container>

  <span
    *ngIf="chat?.conversation_type === ConversationType.group && !isThread"
    class="chat-info__role chat-info__role_group"
    (click)="editGroupChat()">
    {{ 'messageCenter.groupChat' | translate }} <span *ngIf="chat?.access_type !== GroupType.Public">({{ chat?.members?.length }} {{ 'messageCenter.members' | translate }})</span>
  </span>

  <span *ngIf="isThread" class="chat-info__role">
    {{ 'messageCenter.thread' | translate }}
  </span>
</div>

<span *ngIf="showUnreadMessages && chat?.unread_messages_ids?.length" class="unread-messages">
  {{ chat?.unread_messages_ids?.length }}
</span>
