
import { Directive, OnInit, ChangeDetectorRef, inject } from '@angular/core';
import { FiltrationParams } from '@app/core/filtration-params';
import { OptionsMultiselectEvent } from '@app/shared/fields/multiselect-field/models';
import { FilterObject, FilterItem } from '@app/shared/interfaces/filter-item.ifc';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchRequestParams } from '@app/models/request.model';

@UntilDestroy()
@Directive()
export abstract class BaseFilterFormComponent<T extends SearchRequestParams & { advanced_filter: boolean }> implements OnInit {
  abstract FILTER_STORAGE_KEY: string;
  abstract FILTER_ITEMS_STORAGE_KEY: string;

  public filterItems: FilterItem[] = [];
  public params: T;

  readonly filtrationParams = new FiltrationParams<T>({
    advanced_filter: true,
  } as T);

  readonly cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.updateFilteredParams();
  }

  public updateFilteredParams(): void {
    this.params = this.filtrationParams.getParams();
    this.filtrationParams.paramsChange.pipe(untilDestroyed(this)).subscribe(params => {
      this.params = params;
    });
  }

  public reset(): void {
    const params: T = this.filtrationParams.getParams();
    const resetParams: SearchRequestParams & { advanced_filter: boolean } = {
      advanced_filter: true,
    };

    if (params.search && params.lookup_field) {
      resetParams.search = params.search;
      resetParams.lookup_field = params.lookup_field;
    }
    this.filtrationParams.setParams(resetParams as T);
    this.filterItems = [];
  }

  public onDateChange(val: string, key: string, translationKey: string): void {
    const index = this.filterItems.findIndex(item => item.key === key);
    if (val) {
      const item = { key, name: translationKey, value: val };
      index === -1 ? this.filterItems.push(item) : this.filterItems[index] = item;
      this.filtrationParams.patchParams({ [key]: val } as unknown as T);
    } else {
      if (index > -1) {
        this.filterItems.splice(index, 1);
      }
      this.filtrationParams.deleteParamKey(key as keyof T);
    }
    this.cdr.detectChanges();
  }

  public onMultiSelectChange(event: OptionsMultiselectEvent, key: string, translationKey: string): void {
    const index = this.filterItems.findIndex(item => item.key === key);
    const hasValues = event.newValues.length > 0;

    if (event.isSelectedAll || !hasValues) {
      if (index !== -1) {
        this.filterItems.splice(index, 1);
      }
    } else {
      const item = {
        key,
        name: translationKey,
        value: [...event.newValues],
      };
      index === -1 ? this.filterItems.push(item) : this.filterItems[index] = item;
    }
    this.cdr.detectChanges();

    this.filtrationParams.handleMultiselectEvent(event, key as keyof T);
  }

  public storeFilters(params: FilterObject): void {
    localStorage.setItem(this.FILTER_STORAGE_KEY, JSON.stringify(params));
    localStorage.setItem(this.FILTER_ITEMS_STORAGE_KEY, JSON.stringify(this.filterItems));
  }

  public resetStoreFilters(): void {
    localStorage.removeItem(this.FILTER_STORAGE_KEY);
    localStorage.removeItem(this.FILTER_ITEMS_STORAGE_KEY);
  }

  public getStoredFilters(): { params: FilterObject; filterItems: FilterItem[] } | null {
    try {
      const paramsStr = localStorage.getItem(this.FILTER_STORAGE_KEY);
      const filterItemsStr = localStorage.getItem(this.FILTER_ITEMS_STORAGE_KEY);

      if (!paramsStr || !filterItemsStr) return null; // Handle missing values safely

      const params = JSON.parse(paramsStr);
      const filterItems = JSON.parse(filterItemsStr);

      return params ? { params, filterItems } : null;
    } catch (error) {
      console.error('Error parsing stored filters:', error);
      return null; // Prevent app crash if JSON is malformed
    }
  }

}
