import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges, ViewChild
} from '@angular/core';
import { BaseFilterFormComponent } from '@app/core/components';
import { FilterItem } from '@app/shared/interfaces/filter-item.ifc';

@Component({
  selector: 'app-side-filter-panel',
  templateUrl: './side-filter-panel.component.html',
  styleUrls: ['./side-filter-panel.component.scss']
})
export class SideFilterPanelComponent implements OnChanges {
  @Input() public open: boolean = false;
  @Input() filterForm: BaseFilterFormComponent<any>;
  @Output() openChange = new EventEmitter<boolean>();
  @Output() changeFilter = new EventEmitter<any>();
  @Output() filterItemsChanged = new EventEmitter<FilterItem[]>();

  isInit: boolean = true;

  constructor(private readonly elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open) {
      this.toggleFilterPanel();
    }
  }

  private toggleFilterPanel(): void {
    if (this.open) {
      this.openPanel();
    } else {
      if (!this.isInit) {
        this.close();
      }
    }
    this.isInit = false;
  }

  public onClear(): void {
    this.changeFilter.emit({});
    this.filterForm.reset();
    this.filterItemsChanged.emit([]);
  }

  public onFilter(): void {
    const params = this.filterForm.filtrationParams.getParams();

    if (Object.keys(params).length > 0) {
      this.changeFilter.emit(params);
    } else {
      this.changeFilter.emit({});
    }

    this.filterItemsChanged.emit([...this.filterForm.filterItems]);
    this.close();
  }

  public openPanel(): void {
    this.openChange.emit(true);
    this.renderer.removeClass(this.elementRef.nativeElement.querySelector('.filter'), 'filter--leave');
    this.renderer.addClass(this.elementRef.nativeElement.querySelector('.filter'), 'filter--open');
  }

  public close(): void {
    this.openChange.emit(false);
    this.renderer.removeClass(this.elementRef.nativeElement.querySelector('.filter'), 'filter--open');
    this.renderer.addClass(this.elementRef.nativeElement.querySelector('.filter'), 'filter--leave');
  }

}
