import { BillingVsPayrollReport } from '@app/models/reports/report-types/billing-vs-payroll.model';
import { EmployeeWorkingHoursReport } from '@app/models/reports/report-types/employee-working-hours.model';
import { PayrollHoursByCaregiverReport } from '@app/models/reports/report-types/payroll-hours-by-caregiver.model';
import { ScheduleByCaregiverSummaryReport } from '@app/models/reports/report-types/schedule-by-caregiver-summary.model';
import { ScheduleByPayerSumaryReport } from '@app/models/reports/report-types/schedule-by-payer-summary.model';
import { BatchReport } from '@app/models/reports/report-types/batch.model';
import { ReportTemplate } from '@app/models/reports/report-template.model';
import { OptionsLoadStrategy } from '@app/shared/fields/base-select/models';

export interface ReportTemplatesGroup {
  name: string;
  type: ReportGroupType;
  expanded: boolean;
  reports: ReportTemplateData[];
}

export interface ReportTemplateData {
  name: string;
  type: ReportType;
  description: string;
  fields: ReportTemplateField[];
}

export interface ReportTemplateField {
  // Common
  label: string;
  labelTooltip?: string;
  type: ReportFieldType;
  required: boolean;
  formControlName?: string;

  filterDates?: (d: Date | null) => boolean;

  // Date range
  formControlStart?: string;
  formControlEnd?: string;

  // Multiselect field
  canBeSetAsAll?: boolean;
  bindLabel?: string;
  bindValue?: string;
  checkAll?: boolean;
  requestParams?: object | (() => object);
  loadStrategy?: OptionsLoadStrategy;
  optionsLoader?: string;
}

export interface GenerateReportDialogData {
  template?: ReportTemplate;
  reportName: string;
  reportType: ReportType;
  fields: ReportTemplateField[];
}

export enum ReportFieldType {
  TextField = 'text-field',
  MultiSelect = 'multi-select-field',
  Date = 'date',
  DateRange = 'date-range'
}

export enum ReportGroupType {
  Users = 2,
  Billing = 3,
  Payers = 4
}

export enum ReportFileType {
  Pdf,
  Excel,
  Csv
}

export enum ReportType {
  BillingVsPayrollReport,
  EmployeeWorkingHoursReport,
  PayrollHoursByCaregiverReport,
  BatchReport,
  ScheduleByCaregiverSummary,
  ScheduleByPayerSummary
}

export type ReportModelType =
  BillingVsPayrollReport |
  EmployeeWorkingHoursReport |
  PayrollHoursByCaregiverReport |
  BatchReport |
  ScheduleByCaregiverSummaryReport |
  ScheduleByPayerSumaryReport;
