import { Pipe, PipeTransform } from '@angular/core';

import { JoinObjIfc } from '@app/shared/interfaces/pipes.ifc';

const DEFAULT_DELIMITER = ', ';

@Pipe({
  name: 'arrayJoin'
})
export class ArrayJoinPipe implements PipeTransform {
  transform(items: any[], joinObj?: JoinObjIfc): string {
    return ArrayJoinPipe.joinArray(items, joinObj?.delimiter ?? DEFAULT_DELIMITER, joinObj?.field);
  }

  static joinArray<T = any>(items: T[], delimiter = DEFAULT_DELIMITER, fieldName?: keyof T): string {
    if (!items) {
      return '';
    }

    if (!fieldName) {
      return items.join(delimiter);
    }

    return items.map((item) => item[fieldName]).join(delimiter);
  }
}
