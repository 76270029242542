import { inject } from '@angular/core';
import { OptionsLoader } from '@app/shared/fields/base-select/models';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PayerBatchesService } from '../services';
import { Observable } from 'rxjs';
import { toPaginatedResponse } from '../rx-operators';
import { GetPayerBatchRequestParams, SelectPayerUnbatchVisit } from '@app/models/payer/payer-batches.model';
import { map } from 'rxjs/operators';
import { IdNameWidgetResponse } from '@app/models/response.model';
import * as moment from 'moment';

export class BatchesOptionsLoader implements OptionsLoader {
  private payerBatchesService = inject(PayerBatchesService);

  getOptions(params?: GetPayerBatchRequestParams): Observable<PaginatedResponse<IdNameWidgetResponse>> {
    return this.payerBatchesService.getSelectPayerUnbatchVisits({
      widget: 'fk',
      no_limits: true,
      ...(params ?? {}),
    }).pipe(
      toPaginatedResponse(),
      map((response: PaginatedResponse<SelectPayerUnbatchVisit>) => {
        return {
          count: response.count,
          results: response.results.map(visit => ({
            id: visit.id,
            name: `#${ visit.number } ${ visit.name } (${ moment(visit.start_date).format('MM/DD/YY') } - ${moment(visit.end_date).format('MM/DD/YY')  })`,
          }))
        };
      })
    );
  }
}
