import { EntityData } from '@app/models/patient/edit-patient-data.model';
import { Phone } from "@app/models/phone.model";
import { UserPermissions } from './user-permissions.model';
import { UserOfficesDetails } from '@app/models/users/user-profile.model';
import { IdNameWidgetResponse, IdValueWidgetResponse } from "@app/models/response.model";
import { SmokerType } from './caregiver.model';
import { UsersTeam } from "@app/models/users-team.model";

export interface EditUserEmploymentTypeDetailData {
  id: number;
  code: string;
}

export interface EditUserCoordinatorDetail {
  id: number;
  full_name: string;
}

export interface EditUserProfileData {
  id: number;
  animals: number[];
  animals_details: any[];
  gender: number;
  gender_value: string;
  primary_language: number;
  primary_language_detail: EntityData;
  secondary_language: number;
  secondary_language_detail: EntityData;
  notes: string;
  referral_source: string;
  salary_expectations: number;
  skype_id: string;
  trainee_status?: number;
  trainee_status_value?: string;
  contact_method?: number;
  contact_method_value?: string;
  employment_type?: number;
  employment_type_detail?: EditUserEmploymentTypeDetailData;
  work_with_smoker: boolean;
  smokers_preferences: SmokerType[];
  able_to_drive: boolean;
  people_with_diseases: string[];
  work_with_hoyer_lift: boolean;
  smoking: boolean;
  pin_code: number;
  enable_blasting: boolean;
  npi?: number;
  registry_date?: string;
  employment_types: number[];
  employment_types_details: EditUserEmploymentTypeDetailData[];
  licence?: number;
}

export interface EditUserData {
  id: number;
  base_user: number;
  has_connected_base_user: boolean;
  avatar: string;
  phone?: string;
  phone_numbers: Phone[];
  email: string;
  status: number;
  status_value: string;
  service_stopped_from: string;
  service_stopped_reason: string;
  marital_status: MaritalStatus;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  birthday: string;
  ssn: string | null;
  ethnicity: string;
  ethnicity_value?: string;
  country_of_birth: number;
  country_of_birth_detail: { id: number, value: string };
  race: number;
  race_value?: string;
  profile?: EditUserProfileData;
  profile_type: number;
  profile_type_value: string;
  profile_sub_types: number[];
  people_with_diseases: number[];
  people_with_diseases_details: IdValueWidgetResponse[];
  user_offices: number[];
  user_offices_details?: UserOfficesDetails[];
  teams_details: UsersTeam[];
  username: string;
  address_line: string | null;
  address_detail?: any;
  address_details?: string;
  facebook?: string;
  twitter?: string;
  twitter_link?: string;
  linked_in_link: string;
  facebook_link?: string;
  password: string;
  password_confirm: string;
  employment_types: number[];
  employment_types_details: EditUserEmploymentTypeDetailData[];
  coordinator: number;
  coordinator_detail: EditUserCoordinatorDetail;
  gender: string;
  primary_language: number;
  secondary_language?: number;
  contact_method: string;
  animals: number[];
  animals_details: IdNameWidgetResponse[];
  smoking: boolean;
  npi?: number;
  registry_date?: string;
  licence?: number;
  uuid: string;
  features: { homecare: boolean, hr: boolean };
  is_active: boolean;
  permissions: UserPermissions;
  has_new_messages: boolean;
  number: number;
}

export interface UserDisease {
  id: number;
  value: string;
}

interface DataWidget {
  id: number;
  name: string;
}

export enum MaritalStatus {
  Married,
  Single,
  Divorced,
  Widowed,
  Separated,
}
