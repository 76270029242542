import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services';
import { MessageCenterAuthService } from '@app/pages/message-center/services';
import { MC_HOST } from '@app/pages/message-center/shared/MESSAGE_CENTER_URLS';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private messageCenterAuthService: MessageCenterAuthService,) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithToken = this.setTokenIntoHeaders(request);

    return next.handle(requestWithToken);
  }

  private setTokenIntoHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const isRequestToMessageCenter = request.url.startsWith(MC_HOST);
    const token = isRequestToMessageCenter ? this.messageCenterAuthService.getToken() : this.authService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `${ isRequestToMessageCenter ? 'JWT' : 'Token' } ${ token }`
        }
      });
    }

    return request;
  }
}
