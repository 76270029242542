<div class="filter">
  <div class="filter-panel-header">
    <h4 class="filter-panel-header__title">
        {{ 'advancedFilters.title' | translate }}
    </h4>
    <button mat-icon-button class="close-icon" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="filter-panel-body">
    <ng-content></ng-content>
  </div>

  <div class="filter-panel-footer">
    <button class="app-button app-button_blue" (click)="onFilter()">
        {{ 'advancedFilters.button_apply' | translate }}
    </button>
    <button class="app-button app-button_gray" (click)="onClear()">
        {{ 'advancedFilters.button_clear' | translate }}
    </button>
  </div>
</div>

<div class="overflow-shadow" *ngIf="open" (click)="close()"></div>
