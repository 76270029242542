<div
  class="field {{ size }}"
  [attr.tabindex]="readonly ? null : 0"
  (focus)="focusIn()"
  (focusout)="focusOut()"
  (keyup)="keyUp($event)"
  [ngClass]="{
    disabled: isDisabled,
    active: active,
    invalid: invalid
  }">
  <fieldset
    class="field-label"
    [ngClass]="{
      'label-top': optionsAreOpened || optionIndex >= 0,
      'label-hidden': labelHidden
    }">
    <div #label class="label" *ngIf="optionIndex === -1">{{ placeholder }}</div>
  </fieldset>

  <div class="field-text" (click)="toggleDropdown()">
    <div class="value">
      <span *ngIf="optionIndex >= 0">{{ options[optionIndex]?.label | translate }}</span>
    </div>
    <div class="icons">
      <svg-icon *ngIf="value != null && value !== ''" class="icon-reset" src="/assets/icons/close_24dp.svg" (click)="reset()"></svg-icon>

      <div class="icon-arrow">
        <img
          [ngClass]="{ rotated: optionsAreOpened }"
          src="/assets/images/arrow-drop-down-24px.svg"
        />
      </div>
    </div>
  </div>

  <div
    #optionsListWrapper
    class="field-options"
    [ngStyle]="{ 'max-height': maxOptionsListHeight ? maxOptionsListHeight + 'px' : 'auto' }"
    [ngClass]="{
      open: optionsAreOpened,
      bottom: optionsPosition === 'bottom',
      top: optionsPosition === 'top'
    }">

    <div
      *ngFor="let option of options; let i = index"
      class="option {{ size }}"
      passiveTouchStartListener
      (mouseenter)="hoverIndex = i"
      [ngClass]="{ 'option-selected': option.value === value || i === hoverIndex }"
      (mousedown)="optionWasSelected(i)"
      (passiveTouchstart)="optionWasSelected(i)">
      {{ option.label | translate }}
    </div>
  </div>
</div>
