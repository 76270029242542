import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { URLS } from '@app/shared/urls';
import { HttpService } from '@app/core/services';
import {
  Masterweek,
  MasterweekPatientAuth,
  MasterweekPayload,
  MasterweekTableRow,
  MasterWeekVisitCalendar,
  RnAuthVisitsInfo
} from '@app/models/patient/masterweek.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { AuthorizationDetails } from '@app/models/patient/authorizations.model';
import { MasterweekStatus } from '@app/core/enums';

export interface PatientAuthorizationsParams {
  patient_id: number;
  statuses?: MasterweekStatus[];
  sub_auth_info?: number;
  no_limits?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MasterWeekService {

  constructor(
    private http: HttpService
  ) { }

  getMasterWeekList(query: string): Observable<PaginatedResponse<MasterweekTableRow>> {
    return <Observable<PaginatedResponse<MasterweekTableRow>>>this.http
    .GET(`${ URLS.master_week }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPatientAuthorizations(params: PatientAuthorizationsParams): Observable<MasterweekPatientAuth[]> {
    return this.http.GET<MasterweekPatientAuth[]>(URLS.payer_authorization, params);
  }

  getMasterWeekByAuthCode(query: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.master_week }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  addMasterWeek(form: MasterweekPayload): Observable<Masterweek> {
    return <Observable<Masterweek>>this.http
    .POST(`${ URLS.master_week }?utc_offset=${ new Date().getTimezoneOffset() }`, form)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  editMasterWeek(masterWeekId: number, form: MasterweekPayload): Observable<Masterweek> {
    return <Observable<Masterweek>>this.http
    .PUT(`${ URLS.master_week }${ masterWeekId }/?utc_offset=${ new Date().getTimezoneOffset() }`, form)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getMasterWeek(id: number): Observable<Masterweek> {
    return <Observable<Masterweek>>this.http
    .GET(`${ URLS.master_week }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayerAuthorizationById(id: number): Observable<AuthorizationDetails> {
    return <Observable<AuthorizationDetails>>this.http
    .GET(`${ URLS.payer_authorization }${ id }/?sub_auth_info=1`)
    .pipe(
      catchError((error: HttpErrorResponse) => throwError(error))
    );
  }

  deleteMasterWeek(id: number): Observable<void> {
    return <Observable<void>>this.http
    .DELETE(`${ URLS.master_week }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getCalendar(masterWeekId: number, month?: number, year?: number): Observable<Array<MasterWeekVisitCalendar>> {
    let params = '';
    if (month) {
      params += `month=${ month }&`;
    }
    if (year) {
      params += `year=${ year }&`;
    }

    params += `utc_offset=${ new Date().getTimezoneOffset() }`;

    return <Observable<Array<MasterWeekVisitCalendar>>>this.http
    .GET(`${ URLS.master_week }${ masterWeekId }/calendar/?${ params }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getRnAuthVisitsInfoList(authId: number): Observable<RnAuthVisitsInfo> {
    return <Observable<RnAuthVisitsInfo>>this.http
    .GET(`${ URLS.payer_authorization }${ authId }/nurse_visits_info/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
