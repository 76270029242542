export enum ConnectionRequestInitialType {
  FromCompany,
  FromUser
}

export enum ConnectionRequestStatus {
  Active,
  Requested,
  WaitingForApproval,
  Accepted,
  Declined,
  Expired
}