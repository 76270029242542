<div class="file-upload">
  <input hidden #fileInput type="file" [accept]="allowedMimeTypes" [multiple]="isMultiple"
         (change)="onFileSelected($event)">
  <div class="file-upload__controls">
    <div class="upload-button__container">
      <button class="app-button upload-button app-button_blue" matRipple type="button" [disabled]="disabled"
              (click)="fileInput.click()" (blur)="onTouch()">
        {{ title }}
      </button>
    </div>

    <ul class="file-upload__files-list">
      <li *ngFor="let file of files" class="file-upload__file" (click)="openFile(file)">
        <mat-icon class="icon icon--attach material-icons-two-tone icon-green">attach_file</mat-icon>
        <span class="file-upload__file-name ellipsis">{{ file.name | truncate : truncateBy : true }}</span>
        <mat-icon class="icon icon--delete" matRipple (click)="removeFile(file, fileInput)">close</mat-icon>
      </li>
    </ul>
  </div>
</div>
