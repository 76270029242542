import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AfterViewInit, ChangeDetectorRef, Directive, HostBinding, inject, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { OptionsLoader, OptionsLoadStrategy } from './models';
import { NotificationsService } from '@app/core/services';
import { QueryBuilder } from '@app/core/query-builder';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';
import { MergeStrategy } from '@app/core/components';
import { getRemainingScrollDistanceToBottom } from '@app/shared/helper';

@UntilDestroy()
@Directive()
export abstract class BaseSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() bindValue: string = 'id';
  @Input() bindLabel: string = 'name';
  @Input() optionsLoader: OptionsLoader;
  @Input() loadStrategy: OptionsLoadStrategy = 'onFocus';
  @Input() requestParams: object;
  @Input() searchable = false;

  @Input() @HostBinding('class') size: 'small' | 'medium' = 'medium';
  @Input() limit: number = 15;

  @Input() set initialOptions(options: any | object[]) {
    if (!this.optionsWereLoadedAtLeastOnce) {
      this.options = options ? (Array.isArray(options) ? options : [options]) : [];
      this.updateInputView();
    }
  }

  readonly pagination = new PaginationIfc();
  readonly MergeStrategy = MergeStrategy;

  options: object[] = [];
  loading: boolean = false;
  value: any;
  optionsWereLoadedAtLeastOnce = false;
  totalItemsWithoutFiltering: number = 0;
  mergeStrategy: MergeStrategy = MergeStrategy.Replace;
  searchText: string = '';

  protected readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly notificationsService: NotificationsService = inject(NotificationsService);
  protected onChangeCallback: any = () => null;
  protected onTouchedCallback: any = () => null;
  protected lastPayload: object;
  protected wasFocused = false;
  protected readonly SEARCH_DEBOUNCE_TIME = 500;

  private subscription: Subscription;

  protected abstract updateInputView(): void;

  ngOnInit(): void {
    this.pagination.limitChanged(this.limit);
  }

  ngAfterViewInit(): void {
    if (this.loadStrategy === 'onInit') {
      this.loadOptions();
    }
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;

    this.updateInputView();
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onFocus(): void {
    if (this.loadStrategy === 'none') {
      return;
    }

    if (this.isParamsChange() || (!this.wasFocused && this.loadStrategy === 'onFocus')) {
      this.loadOptions();
    }

    this.wasFocused = true;
  }

  onScroll(event: Event): void {
    const isAllItemsLoaded = this.options?.length >= this.pagination.total;
    const scroll = getRemainingScrollDistanceToBottom(<any>event.target);

    if (!isAllItemsLoaded && !this.loading && scroll < 50) {
      this.mergeStrategy = MergeStrategy.Append;
      this.pagination.offsetChanged(this.pagination.offset + this.pagination.limit);
      this.loadOptions();
    }
  }

  protected loadOptions(): void {
    const payload = this.getPayload();

    if (this.loadStrategy === 'searchOnly' && !payload.search) {
      this.mergeStrategy = MergeStrategy.Replace;
      this.onOptionsLoad(this.getDefaultOptionsForSearchOnly());
      this.cdr.markForCheck();
      return;
    }

    this.subscription?.unsubscribe();
    this.loading = true;
    this.cdr.markForCheck();

    this.subscription = this.optionsLoader.getOptions(payload).pipe(
      finalize(() => {
        this.loading = false;
        this.cdr.detectChanges();
      }),
      untilDestroyed(this)
    ).subscribe((response) => {
      this.onOptionsLoad(response);
      this.lastPayload = payload;
      if (!this.optionsWereLoadedAtLeastOnce || (this.isParamsChange() && payload.search == null)) {
        this.totalItemsWithoutFiltering = response.count;
      }
      this.optionsWereLoadedAtLeastOnce = true;
      this.cdr.markForCheck();
    }, error => {
      this.notificationsService.showError(error);
    });
  }

  protected getDefaultOptionsForSearchOnly(): PaginatedResponse {
    return { results: [], count: 0 };
  }

  protected getPayload(): any {
    let payload: any = {
      ...(this.requestParams ?? {}),
    };

    if (this.searchable && this.searchText && this.searchText !== '') {
      payload.search = encodeURIComponent(this.searchText);
    }

    if (!payload.no_limits) {
      payload = { ...payload, ...this.pagination.getParams() };
    }

    return payload;
  }

  protected onOptionsLoad(response: PaginatedResponse): void {
    this.pagination.countChanged(response.count);
    const options = this.mergeStrategy === MergeStrategy.Replace ? response.results : [...(this.options ?? []), ...response.results];
    this.options = this.sortOptions(options);
    this.updateInputView();
  }

  protected sortOptions(options: any[]): any[] {
    return options;
  }

  private isParamsChange(): boolean {
    return !QueryBuilder.isParamsSame(this.getPayload(), this.lastPayload);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  trackByOption(_, option): string | number {
    return option[this.bindValue];
  }
}
