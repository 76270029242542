<mat-table class="availability-table" [dataSource]="items">
  <ng-container matColumnDef="day">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>

    <mat-cell *matCellDef="let row">
      <mat-slide-toggle [ngModel]="!row.day_off" (ngModelChange)="dayOffChange(row)"></mat-slide-toggle>
      <span class="day-name"> {{ dayNames[row.week_day] | translate }} </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateFrom">
    <mat-header-cell *matHeaderCellDef>
      {{ "caregiverDetails.availability_exclusions_popup_column_from" | translate }}
    </mat-header-cell>

    <mat-cell *matCellDef="let row">
      <div *ngFor="let timeSlot of row.time_slots; let slotIndex = index" class="time-slot_from">
        <app-timepicker-field [disabled]="row.day_off"
                              [ngModel]="timeSlot.time_from"
                              (timeChanged)="timeChange($event, row, slotIndex, 'time_from')"
        ></app-timepicker-field>
        <span class="divider">-</span>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateTo">
    <mat-header-cell *matHeaderCellDef>
      {{ "caregiverDetails.availability_exclusions_popup_column_to" | translate }}
    </mat-header-cell>

    <mat-cell *matCellDef="let row">
      <div *ngFor="let timeSlot of row.time_slots; let slotIndex = index; let last = last" class="time-slot_to">
        <app-timepicker-field [disabled]="row.day_off"
                              [ngModel]="timeSlot.time_to"
                              (timeChanged)="timeChange($event, row, slotIndex, 'time_to')"
        ></app-timepicker-field>

        <div *ngIf="timeSlot.invalidRange || timeSlot.collapsingWithOtherSlots" class="errors">
          <mat-icon *ngIf="timeSlot.invalidRange"
                    class="icon-error invalid-range"
                    [matTooltip]="'caregiverDetails.availability_exclusions_popup_invalidRangeError' | translate">
            error
          </mat-icon>

          <mat-icon *ngIf="timeSlot.collapsingWithOtherSlots"
                    class="icon-error overlapping"
                    [matTooltip]="'caregiverDetails.availability_exclusions_popup_overlappingError' | translate">
            join_right
          </mat-icon>
        </div>

        <mat-icon *ngIf="row.time_slots.length > 1 && !row.day_off" class="material-icons-two-tone icon-red"
                  [matTooltip]="'caregiverDetails.availability_exclusions_popup_deleteTimeSlot' | translate"
                  (click)="deleteTimeSlot(row.week_day, slotIndex)"
        >delete
        </mat-icon>

        <mat-icon *ngIf="!row.day_off && last && row.time_slots.length < 4"
                  class="material-icons-outlined add-timeslot-icon"
                  [matTooltip]="'caregiverDetails.availability_exclusions_popup_addTimeSlot' | translate"
                  (click)="addTimeSlot(row.week_day)"
        >add_circle_outline</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
