import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService, RealtimeService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  BulkChangeProvider,
  BulkChangeStatus,
  EDIHistory,
  EDIRevision,
  EDISubmitResponse,
  EdiClaimProviderInfo,
  EdiClaimProviderInfoParams,
  EdiDownload,
  EdiDownloadResponse,
  EdiSubmit,
  PayerInvoiceClaim,
  PayerInvoiceNestedDetails,
  PayerInvoiceUploadFilePayload,
  PayerInvoiceFile,
  PayerInvoiceNestedEDI,
  PayerInvoicing, GetPayerInvoiceRequestParams
} from '@app/models/payer/invoicing.model';
import { ResponseSuccess } from '@app/models/response.model';
import { tap } from 'rxjs/operators';
import { WsInvoiceClamSubmitPayload } from '@app/core/services/websocket/ws-payload.models';
import { WsEvent } from '@app/core/services/websocket/ws.models';

@Injectable({
  providedIn: 'root'
})
export class InvoicingService {

  constructor(
    private http: HttpService,
    private realtimeService: RealtimeService,
  ) { }

  getPayerInvoicing(params: object): Observable<PaginatedResponse<PayerInvoicing>> {
    return this.http.GET<PaginatedResponse<PayerInvoicing>>(URLS.payer_invoice_batch, params);
  }

  getPayerInvoiceClaim(params: GetPayerInvoiceRequestParams): Observable<PaginatedResponse<PayerInvoiceClaim>> {
    return this.http.GET<PaginatedResponse<PayerInvoiceClaim>>(`${ URLS.payer_invoice_claim }`, params);
  }

  getPayerInvoiceNestedDetails(claimId: number, batchId: number): Observable<PayerInvoiceNestedDetails> {
    return this.http.GET<PayerInvoiceNestedDetails>(`${ URLS.payer_invoice_claim }${ claimId }/?batch_id=${ batchId }`);
  }

  uploadEdiFiles(data: PayerInvoiceUploadFilePayload): Observable<PayerInvoiceFile[]> {
    return this.http.POST<PayerInvoiceFile[]>(URLS.payer_invoice_claim_uploaded_file, data);
  }

  deleteEdiFile(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.payer_invoice_claim_uploaded_file }${ id }/`);
  }

  getElectronicDataInterchange(payerId: number, batchId: number): Observable<PayerInvoiceNestedEDI> {
    return this.http.GET<PayerInvoiceNestedEDI>(`${ URLS.payer_invoice_claim }${ payerId }/electronic_data_interchange/?batch_id=${ batchId }`);
  }

  getEdiHistory(claimId: number, params: object): Observable<PaginatedResponse<EDIHistory>> {
    return this.http.GET<PaginatedResponse<EDIHistory>>(`${ URLS.history }edi_claim/${ claimId }/`, params);
  }

  submitInvoiceClaim(claimId: number, batchId: number): Observable<EDISubmitResponse> {
    return this.http.POST<EDISubmitResponse>(`${ URLS.payer_invoice_claim }${ claimId }/submit/?batch_id=${ batchId }`).pipe(
      tap(() => this.realtimeService.emitInternalEvent<WsInvoiceClamSubmitPayload>({
        event: WsEvent.fe_internal_invoice_claim_submit,
        payload: { batchId, claimId }
      }))
    );
  }

  changeInvoiceClaimStatus(payerId: number, payload: EDIRevision): Observable<EDIRevision> {
    return this.http.PUT<EDIRevision>(`${ URLS.payer_invoice_claim_status }${ payerId }/`, payload);
  }

  getEdiClaimsProviderInfo(id: number, params: EdiClaimProviderInfoParams): Observable<EdiClaimProviderInfo[]> {
    return this.http.GET<EdiClaimProviderInfo[]>(`${ URLS.payer_invoice_batch }${ id }/edi_claims_providers_info/`, params);
  }

  submitEdi(id: number, data: EdiSubmit): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.payer_invoice_batch }${ id }/edi_submit/`, data);
  }

  downloadEdi(id: number, data: EdiDownload): Observable<EdiDownloadResponse> {
    return this.http.POST<EdiDownloadResponse>(`${ URLS.payer_invoice_batch }${ id }/edi_download_837/`, data);
  }

  bulkChangeProvider(data: BulkChangeProvider[]): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.payer_invoice_claim }bulk_change_provider/`, data);
  }

  bulkChangeStatus(data: BulkChangeStatus): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.payer_invoice_claim }bulk_change_status/`, data);
  }
}
