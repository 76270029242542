import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, NgControl, ValidationErrors } from '@angular/forms';

import { TranslationData } from '@app/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';

// TODO: Add localization to all errors and check is all of them needed
const DEFAULT_ERRORS_MESSAGES = {
  mask: 'The value is wrong for current mask.',
  number: 'The value is not a valid number.',
  time: 'Time format is invalid.',
  step: 'The value is wrong format.',
  url: 'Invalid URL format.',

  min: 'commonErrors.field_min',
  max: 'commonErrors.field_max',
  minlength: 'commonErrors.field_minLength',
  maxlength: 'commonErrors.field_maxLength',
  minNumbersLength: 'commonErrors.field_minNumbersLength',
  maxNumbersLength: 'commonErrors.field_maxNumbersLength',
  invalidName: 'commonErrors.name_invalid',
  email: 'commonErrors.email_notValid',
  required: 'commonErrors.field_required',
  phoneNumber: 'commonErrors.phone_notValid',
  empty_geo: 'commonErrors.address_notValid',
  password_mismatch: 'commonErrors.password_mismatch',
  expirationDate: 'commonErrors.date_expirationDate',
  existUserName: 'commonErrors.name_existUserName',
  existPayerName: 'commonErrors.name_existPayerName',
  validEmailAddress: 'commonErrors.email_notValid',
  uniqueNameBasicDiscipline: 'commonErrors.uniqueNameAndBasicDiscipline',
  minDateInput: 'commonErrors.date_minDate_01011900',
  maxDateToday: 'commonErrors.date_maxDateToday',
  startDateBiggerThanEnd: 'commonErrors.date_startDateIsBiggerThanEndDate',
  endTimeLessThanStartTime: 'commonErrors.date_endTimeIsEarlierThanStartTime',
  endDateSmallerThanStart: 'commonErrors.date_endDateIsEarlierThanStartDate',
  mustBeUnique: 'commonErrors.must_be_unique',
  ssnUsed: 'commonErrors.ssnUsed'
};

export const SERVER_CONTROL_ERROR_KEY = 'serverError';

export type ControlErrors = {
  [key: string]: ControlErrorValue
};

type ControlErrorValue = boolean | string | TranslationData;

@UntilDestroy()
@Component({
  selector: 'app-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlErrorsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() field: AbstractControl | FormControl | FormArray | NgControl;
  @Input() overriddenMessages: ControlErrors = {};
  @Input() shouldBeTouched: boolean = true;
  @Input() shouldBeDirty: boolean = false;
  @Input() heightless = false;

  errorMessage: string;

  get control(): AbstractControl {
    return this.field instanceof NgControl ? this.field.control : this.field;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.updateMessage();
  }

  ngAfterViewInit(): void {
    merge(
      this.control.valueChanges,
      this.control.statusChanges
    ).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateMessage();
      });

    this.subscribeToControlTouchEvent();
  }

  private updateMessage(): void {
    this.errorMessage = this.getErrorMessage();
    this.cd.markForCheck();
  }

  getErrorMessage(): string {
    const controlErrors: ValidationErrors = this.control?.errors ?? {};
    const errorKey = Object.keys(controlErrors)[0];

    if (!errorKey) {
      return '';
    }

    const errorHasEmbeddedMessage = typeof controlErrors[errorKey] === 'string' || controlErrors[errorKey] instanceof TranslationData;

    if (errorHasEmbeddedMessage) {
      return this.generateEmbeddedMessage(controlErrors, errorKey);
    } else {
      return this.generateDefaultMessage(controlErrors, errorKey);
    }
  }

  private generateDefaultMessage(errors: ControlErrors, errorKey: string): string {
    const message = { ...DEFAULT_ERRORS_MESSAGES, ...this.overriddenMessages }[errorKey];

    if (!message) {
      console.error('Validation error doesn\'t registered: ', errorKey);
      return;
    }

    return this.translate.instant(message, errors[errorKey]);
  }

  private generateEmbeddedMessage(errors: ControlErrors, key: string): string {
    const value: ControlErrorValue = errors[key];

    if (value instanceof TranslationData) {
      return this.translate.instant(value.key, value.interpolationObj);
    }

    return <string>errors[key] ?? '';
  }

  // Overriding #markAsTouched method of the control because Angular doesn't provide any event when control was touched.
  private subscribeToControlTouchEvent(): void {
    const control: any = this.control;

    control.__originalMarkAsTouched = control.__originalMarkAsTouched ?? control.markAsTouched;
    control.markAsTouched = () => {
      control.__originalMarkAsTouched();
      this.updateMessage();
    };
  }

  private unsubscribeFromControlTouchEvent(): void {
    const control: any = this.control;
    if (control) {
      control.markAsTouched = control.__originalMarkAsTouched;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeFromControlTouchEvent();
  }
}
