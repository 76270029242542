// AVAILABILITY
export interface CaregiverAvailability {
  id: number;
  days_schedule_details: AvailabilityDaySchedule[];
}

export interface CaregiverAvailabilityUpdate {
  days_schedule_data: AvailabilityDaySchedule[];
}

// CALENDAR
export interface CaregiverAvailabilityCalendar {
  date: string;
  is_available: boolean;
  has_exclusion: boolean;
  time_slots_count: number;
}

export interface CaregiverAvailabilityCalendarGetParams {
  month: number;
  year: number;
}

// EXCLUSION
export interface AvailabilityExclusion {
  id: number;
  time_slots: AvailabilityTimeSlot[];
  days_schedule_details: AvailabilityDaySchedule[];
}

export interface AvailabilityExclusionCreateUpdate {
  id?: number;
  date_from: string;
  date_to: string;
  days_schedule_data: AvailabilityDaySchedule[];
  user: number;
}

export interface AvailabilityDaySchedule {
  id: number;
  week_day: AvailabilityWeekDay;
  week_day_value?: string;
  time_slots: AvailabilityTimeSlot[];
  day_off: boolean;
}

export interface AvailabilityExclusionDialogData {
  mode: string;
  title: string;
  buttonLabel: string;
  userId: number;
  exclusion?: AvailabilityExclusion;
}

export interface AvailabilityTimeSlot {
  id?: number;
  time_from: string;
  time_to: string;
}

export enum AvailabilityWeekDay {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}
