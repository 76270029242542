<input
  #input
  autocomplete="off"
  onlyNumber
  class="form-control"
  [allowNegative]="allowNegative"
  [value]="inputText"
  [placeholder]="placeholder"
  [class.is-invalid]="invalid"
  [readonly]="disabled"
  [step]="step"
  (focus)="onInputFocus(input)"
  (input)="onInputChange($event.target.value, input)"
  (blur)="onInputBlur()"
  (keydown.arrowUp)="stepUp($event.target.value)"
  (keydown.arrowDown)="stepDown($event.target.value)"
/>
