import { Component, ContentChild, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterObject, FilterItem } from '@app/shared/interfaces/filter-item.ifc';
import { BaseFilterFormComponent } from '@app/core/components';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  // tslint:disable-next-line:variable-name
  private _searchOption: string;
  public isFilterOpen: boolean = false;
  public filterItems: FilterItem[] = [];

  @Output() filter = new EventEmitter<FilterObject>();
  @ContentChild('filterForm') filterForm: BaseFilterFormComponent<any>;

  @Input() searchText: string;
  @Input()
  get searchOption(): string {
    return this._searchOption;
  }

  set searchOption(value: string) {
    this._searchOption = value;

    // Ensure filterForm is initialized before using it
    if (this.filterForm) {
      this.filterForm.filtrationParams.patchParams({ lookup_field: value });
    }
  }

  public openFilterChange(status: boolean): void {
    this.isFilterOpen = status;
  }

  public filterChange(val: FilterObject): void  {
    this.updateFiltrationParams(val);
  }

  public assignFilterItems(val: FilterItem[]): void {
    this.filterItems = val;
  }

  public onDeleteFilterItem(filterItem: FilterItem): void {
    this.updateFilterItems(filterItem);
    this.filterForm.filtrationParams.deleteParamKey(filterItem.key);
    this.updateFiltrationParams(this.filterForm.filtrationParams.getParams());
  }

  public searchTextChange(searchText: string): void {
    this.filterForm.filtrationParams.patchParams({ search: searchText, lookup_field: this.searchOption });
    this.emitFilterValue();
  }

  private updateFilterItems(filterItem: FilterItem): void {
    this.filterForm.filterItems = this.filterForm.filterItems.filter(i => i.key !== filterItem.key);
    this.filterItems = [...this.filterForm.filterItems];
  }

  private updateFiltrationParams(payload: FilterObject): void {
    const searchText: string = this.filterForm.filtrationParams.getParams().search || this.searchText;
    if (searchText) {
      payload['search'] = this.searchText;
      payload['lookup_field'] = this.searchOption;
    }
    this.filterForm.filtrationParams.setParams(payload);
    this.emitFilterValue();
  }

  private emitFilterValue(): void {
    const params: FilterObject = { ...this.filterForm.filtrationParams.getParams() };
    this.filter.emit(params);
    if (!this.isEmptyObject(params)) {
      this.filterForm.storeFilters(params);
    } else {
      this.filterForm.resetStoreFilters();
    }
  }

  private isEmptyObject(obj: object): boolean {
    return obj && Object.keys(obj).length === 0;
  }
}
