import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnChanges,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { OptionsMultiselectEvent } from '@app/shared/fields/multiselect-field/models';
import { BaseSelectComponent } from '@app/shared/fields/base-select/select.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MergeStrategy } from '@app/core/components';

enum ToggleButtonAction {
  None,
  PressedSelectAll,
  PressedUnselectAll
}

@Component({
  selector: 'app-multiselect-field',
  templateUrl: 'multiselect-field.component.html',
  styleUrls: ['multiselect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectFieldComponent),
      multi: true
    },
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'app-multiselect-overlay' } },
  ]
})
export class MultiselectFieldComponent extends BaseSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() translateLabel = false;
  @Input() showValueAsCounter = false;
  @Input() @HostBinding('class.clearable') clearable = false;

  // The behavior when the component shows value as 'All' should be disabled if we are using a lazy load strategy with initialOptions.
  // So before the moment, we load all options we can't know is all options selected.
  // Maybe better to determine this behavior depending on whether initialOptions was defined instead of setting it manually.
  @Input() showValueAsAllWhenEveryOptionSelected = true;
  @Input() showValueAsAllWhenNoOptionSelected = false;
  @Input() showToggleAllOption = false;
  @Input() autoselectAllByDefault = false;
  @Input() placeholder: string = 'placeholders.noSelectedOptions';
  @Input() allOptionLabel = 'common.all';
  @Input() set viewInputPrefix(value: string) {
    this.inputPrefix = value ? `${ value }: ` : '';
  }

  @Output() optionSelect: EventEmitter<OptionsMultiselectEvent> = new EventEmitter<OptionsMultiselectEvent>();

  value: any[] = [];
  selectedLabels: string[] = [];
  selectedOptions: object[] = [];
  inputPrefix: string = '';
  searchControl = new FormControl('');

  private toggleAllButtonLastAction: ToggleButtonAction = ToggleButtonAction.None;
  private unselectedValues: any[] = [];

  get isAllOptionsSelected(): boolean {
    return (this.value?.length && this.value?.length >= this.totalItemsWithoutFiltering) ||
      (this.autoselectAllByDefault && this.toggleAllButtonLastAction !== ToggleButtonAction.PressedUnselectAll) &&
      !this.unselectedValues.length;
  }

  writeValue(value: any) {
    super.writeValue(value);
    this.assignUnselectedValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    const autoSelectChange = changes['autoselectAllByDefault'];
    if (autoSelectChange?.previousValue !== autoSelectChange?.currentValue && autoSelectChange.currentValue) {
      setTimeout(() => this.selectAll());
    }
  }

  get isFilterApplied(): boolean {
    return !!this.searchText && this.searchText !== '';
  }

  get showValueAsAll(): boolean {
    return this.showValueAsAllWhenEveryOptionSelected && this.isAllOptionsSelected ||
      (!this.optionsWereLoadedAtLeastOnce && this.autoselectAllByDefault) ||
      this.showValueAsAllWhenNoOptionSelected && !this.value?.length;
  }

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.searchControl.valueChanges.pipe(
      debounceTime(this.SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((query) => {
      this.searchText = query;
      this.mergeStrategy = MergeStrategy.Replace;
      this.pagination.offsetChanged(0);
      this.loadOptions();
      this.cdr.markForCheck();
    });
  }

  onOptionSelected(): void {
    this.updateInputView();
    this.onChangeCallback(this.value);
    this.updateUnselectedValues();

    this.optionSelect.emit({
      newValues: this.value,
      optionsData: this.selectedOptions,
      changedByAutoselect: false,
      isSelectedAll: (this.loadStrategy !== 'searchOnly' && this.value.length === this.totalItemsWithoutFiltering)
        || (this.showValueAsAllWhenNoOptionSelected && !this.value.length),
    });
  }

  toggleAllOptions(): void {
    this.isAllOptionsSelected ? this.unselectAll() : this.selectAll();
    this.onOptionSelected();
    this.cdr.markForCheck();
  }

  private assignUnselectedValues(): void {
    this.unselectedValues = this.options.map(item => item[this.bindValue]);
    this.cdr.markForCheck();
  }

  private selectAll(): void {
    this.value = this.options.map(option => option[this.bindValue]);
    this.unselectedValues = [];
    this.toggleAllButtonLastAction = ToggleButtonAction.PressedSelectAll;
    this.cdr.markForCheck();
  }

  private unselectAll(): void {
    this.value = [];
    this.assignUnselectedValues();
    this.toggleAllButtonLastAction = ToggleButtonAction.PressedUnselectAll;
    this.cdr.markForCheck();
  }

  private isSelectLoadedItems(): boolean {
    return this.autoselectAllByDefault && this.toggleAllButtonLastAction !== ToggleButtonAction.PressedUnselectAll
      || this.toggleAllButtonLastAction === ToggleButtonAction.PressedSelectAll;
  }

  protected override onOptionsLoad(response: PaginatedResponse) {
    // For some reason if the value is changing at the same moment as we click on the mat-select then panel doesn't open for very first click.
    // So setTimeout fix this by setting the value after the panel is opened. It looks like Angular Material issue (it reproducing with v.14).
    setTimeout(() => {
      if (this.isSelectLoadedItems()) {
        response.results.forEach(item => {
          const itemValue = item[this.bindValue];
          if (!this.value?.includes(itemValue) && !this.unselectedValues.includes(itemValue)) {
            this.value = [...(this.value ?? []), itemValue];
          }
        });
      }

      super.onOptionsLoad(response);
    });
  }

  protected override sortOptions(options: any[]): any[] {
    const selected = [];
    const unselected = [];
    options.forEach((option) => this.value?.includes(option[this.bindValue]) ? selected.push(option) : unselected.push(option));

    return [...selected, ...unselected];
  }

  protected override updateInputView(): void {
    this.selectedOptions = (this.value ?? [])
      .map((value) => [...this.selectedOptions, ...this.options].find(option => option[this.bindValue] === value))
      .filter(Boolean);
    this.selectedLabels = this.selectedOptions.map(option => {
      const label = option ? option[this.bindLabel] : null;
      return this.translateLabel && label ? this.translateService.instant(label) : label;
    });
    this.cdr.markForCheck();
  }

  protected override getDefaultOptionsForSearchOnly(): PaginatedResponse {
    return { results: this.selectedOptions, count: this.selectedOptions.length };
  }

  autoselectAllItems(): void {
    this.writeValue(this.options.map(option => option[this.bindValue]));
    this.onChangeCallback(this.value);
    this.optionSelect.emit({
      newValues: this.value,
      optionsData: this.selectedOptions,
      changedByAutoselect: true,
      isSelectedAll: true
    });
    this.cdr.markForCheck();
  }

  reset(): void {
    this.value = [];
    this.selectedOptions = [];
    this.unselectedValues = [];
    this.onOptionSelected();
    this.cdr.markForCheck();
  }

  onPanelOpen(): void {
    this.options = this.sortOptions(this.options);
    this.cdr.markForCheck();
  }

  private updateUnselectedValues(): void {
    this.unselectedValues = this.unselectedValues.filter(value => !this.value.includes(value));
    this.options.forEach((option) => {
      const optionValue = option[this.bindValue];
      if (!this.value.includes(optionValue) && !this.unselectedValues.includes(optionValue)) {
        this.unselectedValues.push(optionValue);
        this.cdr.markForCheck();
      }
    });
  }
}
