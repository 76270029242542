import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { fromUTCtoDateTime } from '@app/shared/helper';
import { NotificationsService, VisitDetailsService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  GetVisitHistoryRequestParams
} from '@app/models/patient/visit-details/history.model';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize } from 'rxjs/operators';
import { StaticOptionsLoader } from '@app/core/field-options-loaders';
import { History, HistoryChangeType } from '@app/models/history.model';

@UntilDestroy()
@Component({
  selector: 'app-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryTabComponent implements OnInit {
  @Input() visitId: number;

  isLoading: boolean;
  historyList: History[] = [];
  readonly displayedColumns = ['changes', 'description', 'date', 'author'];
  pagination: PaginationIfc = new PaginationIfc();

  rangeDate = { start: '', end: '' };
  startDatePicker = new FormControl();
  endDatePicker = new FormControl();
  selectedTypes: HistoryChangeType[];

  readonly typesOptionsLoader = new StaticOptionsLoader([
    { value: HistoryChangeType.FieldChanged, label: 'visitDetails.tabHistory_select_type_fieldChanged' },
    { value: HistoryChangeType.StatusChanged, label: 'visitDetails.tabHistory_select_type_statusChanged'},
    { value: HistoryChangeType.Added, label: 'visitDetails.tabHistory_select_type_added' },
    { value: HistoryChangeType.Deleted, label: 'visitDetails.tabHistory_select_type_deleted' },
    { value: HistoryChangeType.Assign, label: 'visitDetails.tabHistory_select_type_visitAssign' },
    { value: HistoryChangeType.Blasting, label: 'visitDetails.tabHistory_select_type_visitBlasting' }
  ]);

  constructor(
    private visitDetailsService: VisitDetailsService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.loadHistory();

    this.visitDetailsService.getRefreshCurrentTab
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe((response: boolean) => this.loadHistory());
  }

  loadHistory(): void {
    this.isLoading = true;
    this.historyList = [];
    this.cdr.detectChanges();

    const params: GetVisitHistoryRequestParams = this.pagination.getParams();

    if (this.selectedTypes) {
      params.types = this.selectedTypes;
    }

    if (this.rangeDate.start && this.rangeDate.end) {
      params.start = this.rangeDate.start;
      params.end = this.rangeDate.end;
    }

    this.visitDetailsService.getVisitHistory(this.visitId, params).pipe(
      finalize(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe((response: PaginatedResponse<History>) => {
      this.historyList = response.results;
      this.pagination.countChanged(response.count);
    }, (error) => this.notificationsService.showError(error));
  }

  convertDateTime(date: string): string {
    return fromUTCtoDateTime(date);
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
    const start = dateRangeStart.value;
    let end = dateRangeEnd.value;
    if (!end) {
      return;
    }
    this.rangeDate.start = start;
    this.rangeDate.end = end;

    this.pagination.offsetChanged();
    this.loadHistory();
  }

  resetTimeRange(): void {
    this.startDatePicker.setValue(null);
    this.endDatePicker.setValue(null);

    this.rangeDate.start = '';
    this.rangeDate.end = '';

    this.pagination.offsetChanged();
    this.loadHistory();
  }

  changeTypes(newTypes: HistoryChangeType[]): void {
    this.selectedTypes = newTypes;
    this.pagination.offsetChanged();
    this.loadHistory();
  }
}
