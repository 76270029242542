import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, filter, debounceTime, tap } from 'rxjs/operators';

import { LoadedMessageFile, MessagePayload } from '@app/pages/message-center/models/message.model';
import { ConversationType, UserChat } from '@app/pages/message-center/models/chat.model';
import { MessageStatus, MessageType } from '@app/pages/message-center/shared/constants';
import { ChatService, MessageCenterAuthService } from '@app/pages/message-center/services';
import { oneOfValidator } from '@app/pages/message-center/shared/validators';
import { NotificationsService } from '@app/core/services';
import { Websocket } from '@app/core/services/websocket/websocket';
import { WsAction } from '@app/core/services/websocket/ws.models';
import { MCProfile } from '@app/pages/message-center/models/user.model';

@UntilDestroy()
@Component({
  selector: 'app-send-message-form',
  templateUrl: './send-message-form.component.html',
  styleUrls: ['./send-message-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageFormComponent implements OnInit {
  @Input() chat: UserChat;
  @Input() threadMessageId?: number;
  @Input() sendImmediatelyWhenUploadFile = false;
  @Input() chatWebsocket: Websocket;

  form: FormGroup;
  loadedFiles: LoadedMessageFile[] = [];
  isSending: boolean;
  isTyping = false;

  readonly MessageType = MessageType;

  private profile: MCProfile = this.messageCenterAuthService.getProfile();

  readonly messageTypeOptions = [
    { value: MessageType.sms, title: 'messageCenter.messageType_sms' },
    { value: MessageType.email, title: 'messageCenter.messageType_email' },
    { value: MessageType.internal, title: 'messageCenter.messageType_text' }
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private chatService: ChatService,
    private notificationsService: NotificationsService,
    private messageCenterAuthService: MessageCenterAuthService,
  ) {
  }

  get files(): FormArray {
    return this.form.get('files') as FormArray;
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = new FormBuilder().group({
      channel: [MessageType.internal, [Validators.required]],
      subject: '',
      message: '',
      files: new FormBuilder().array([])
    }, {
      validators: [oneOfValidator('message', 'files')]
    });

    this.form.valueChanges.pipe(
      filter(() => this.chatWebsocket?.isConnected),
      filter((value) => value.channel === MessageType.internal),
      tap(() => {
        if (!this.isTyping) {
          this.sendTypingEvent(WsAction.user_typing_start);
          this.isTyping = true;
        }
      }),
      debounceTime(1000),
      untilDestroyed(this)
    ).subscribe(() => {
      if (this.isTyping) {
        this.isTyping = false;
        this.sendTypingEvent(WsAction.user_typing_end);
      }
    });
  }

  addFiles(event: LoadedMessageFile[]): void {
    this.loadedFiles = event;
    this.updateFilesInForm();

    if (this.sendImmediatelyWhenUploadFile) {
      this.submit();
    }
  }

  private updateFilesInForm(): void {
    this.files.clear();
    this.loadedFiles.forEach(file => {
      this.files.push(this.addFileControl(file));
    });
  }

  private addFileControl(file: LoadedMessageFile): FormGroup {
    return new FormBuilder().group({
      name: [file.name],
      file: [file.file]
    });
  }

  removeAttachedFile(index: number): void {
    this.loadedFiles.splice(index, 1);
    this.updateFilesInForm();
  }

  submit(): void {
    const payload: MessagePayload = this.getPayload();
    this.resetForm();
    this.isSending = true;
    this.cdr.markForCheck();

    this.chatService.sendMessage(payload).pipe(
      finalize(() => {
        this.isSending = false;
        this.cdr.markForCheck();
      }),
      untilDestroyed(this)
    ).subscribe({
      error: (error) => this.notificationsService.showError(error),
    });
  }

  private resetForm(): void {
    this.form.controls.message.setValue(null);
    this.form.controls.subject.setValue(null);
    this.files.clear();
    this.loadedFiles = [];
  }

  private sendTypingEvent(action: WsAction.user_typing_start | WsAction.user_typing_end): void {
    this.chatWebsocket.sendMessage({
      action,
      payload: {
        user_id: this.profile.id,
        chat_id: this.chat.id
      }
    });
  }

  private getPayload(): MessagePayload {
    const form = this.form.value;

    let recipients: number[];
    if (this.chat.conversation_type === ConversationType.direct) {
      recipients = [this.chat.direct_to.id];
    } else {
      recipients = this.chat.members.map(client => client.id);
    }

    const payload: MessagePayload = {
      chat: this.chat.id,
      recipients,
      status: MessageStatus.created,
      channel: form.channel,
      text: form.message,
      subject: form.subject,
      files_data: form.files.map(file => {
        return {
          file: file.file
        };
      }),
    };

    if (this.threadMessageId) {
      payload.reply_to = this.threadMessageId;
    }

    return payload;
  }

  changeMessageType(messageType: MessageType): void {
    if (messageType === MessageType.email) {
      this.form.controls.subject.setValidators(Validators.required);
    } else {
      this.form.controls.subject.clearValidators();
    }
    this.form.controls.subject.updateValueAndValidity();
  }
}
