import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterComponent } from './search-filter.component';
import { SideFilterPanelModule } from '@app/shared/side-filter-panel/side-filter-panel.module';
import { TranslateModule } from '@ngx-translate/core';
import { SearchForFilterModule } from '@app/shared/search-for-filter/search-for-filter.module';


@NgModule({
  declarations: [
    SearchFilterComponent
  ],
  exports: [
    SearchFilterComponent
  ],
    imports: [
        CommonModule,
        SideFilterPanelModule,
        TranslateModule,
        SearchForFilterModule
    ]
})
export class SearchFilterModule { }
