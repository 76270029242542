import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services/http/http.service';
import { URLS } from '@app/shared/urls';
import { CheckAvailableSsnParams, CheckAvailableSsnResponse } from '@app/models/users/available-ssn.model';
import { SsnVerificationType } from '@app/core/enums';
import { ConnectionRequestParams, ConnectionRequest } from '@app/models/users/connection-request.model';
import { BaseProfileResponse, BaseProfileParams } from '@app/models/users/base-profile.model';

@Injectable()
export class SsnVerificationService {

  constructor(
    private http: HttpService
  ) { }

  checkSsnAvailability(params: CheckAvailableSsnParams): Observable<CheckAvailableSsnResponse> {
    return this.http.POST<CheckAvailableSsnResponse>(URLS.check_available_ssn, params);
  }

  findProfileBySsn(params: BaseProfileParams): Observable<BaseProfileResponse> {
    return this.http.GET<BaseProfileResponse>(`${ URLS.base_profile }find_by_ssn/`, params);
  }

  connectionRequest(params: ConnectionRequestParams): Observable<ConnectionRequest> {
    return this.http.POST<ConnectionRequest>(URLS.connection_request, params);
  }

  resendConnection(id: number, type: SsnVerificationType): Observable<ConnectionRequest> {
    return this.http.POST<ConnectionRequest>(`${ URLS.connection_request }${ id }/resend/`, { notification_type: type });
  }

  cancelConnection(id: number): Observable<ConnectionRequest> {
    return this.http.POST<ConnectionRequest>(`${ URLS.connection_request }${ id }/cancel/`);
  }

  confirmVerificationCode(id: number, code: string): Observable<ConnectionRequest> {
    return this.http.POST<ConnectionRequest>(`${ URLS.connection_request }${ id }/confirm_by_verification_code/`, { verification_code: code });
  }
}
