import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { HttpService } from '@app/core/services';
import { CommunicationMode, ExternalLink } from '../models/communication.model';
import { MESSAGE_CENTER_URLS } from '../shared/MESSAGE_CENTER_URLS';
import { MCProfile } from '@app/pages/message-center/models/user.model';

const PROFILE_STORAGE_KEY = 'message-center.user_account';
const TOKEN_STORAGE_KEY = 'message-center.access_token';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterAuthService {
  constructor(private http: HttpService) {
  }

  authorize(): Observable<any> {
    const hasToken = !!this.getToken();

    if (hasToken) {
      return of(null);
    }

    return this.loadToken().pipe(
      tap((response) => this.saveToken(response.jwt_token)),
      switchMap(() => this.loadProfile().pipe(tap(profile => this.saveProfile(profile))))
    );
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  getProfile(): MCProfile {
    return JSON.parse(localStorage.getItem(PROFILE_STORAGE_KEY));
  }

  logout(): void {
    localStorage.removeItem(PROFILE_STORAGE_KEY);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  private saveToken(token: string): void {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
  }

  private loadToken(): Observable<ExternalLink> {
    return this.http.POST<ExternalLink>(MESSAGE_CENTER_URLS.external_link, {
      communication_mode: CommunicationMode.JwtToken,
      user_to: null
    });
  }

  private loadProfile(): Observable<MCProfile> {
    return this.http.GET<MCProfile>(`${ MESSAGE_CENTER_URLS.user_account }${ null }/`, { is_own: 'yes' });
  }

  private saveProfile(profile: MCProfile): void {
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(profile));
  }
}
