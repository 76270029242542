import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgModelBase } from '@app/shared/ng-model.base';

import { GmapUtilsService } from '@app/core/services';
import { GeoJsonIfc } from '@app/shared/interfaces/geo-json.ifc';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddressAutocompleteFieldComponent),
  multi: true
};

@Component({
  selector: 'app-address-autocomplete-field',
  templateUrl: './address-autocomplete-field.component.html',
  styleUrls: ['./address-autocomplete-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressAutocompleteFieldComponent extends NgModelBase<string> {
  active: boolean = false;
  isDisabled: boolean = false;
  labelTop: boolean = false;
  legendWidth: number = 100;

  @Input() source = '';
  @Input() labelHidden = false;
  @Input() invalid = false;
  @Input() maxlength = 100;
  @Input() placeholder = '';
  @Input() optionsPosition = 'bottom';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() autocompleteTypes = ['address'];
  @Input() displayFields = ['streetNumber', 'streetName'];
  @Input() displayDelimiter = ' ';
  @Input() readonly: boolean = false;

  @Output() addressSelected = new EventEmitter<GeoJsonIfc>();
  @Output() addressDeleted = new EventEmitter<void>();
  @Output() textChange = new EventEmitter<string>();
  @Output() selectedEmitter = new EventEmitter<number>();
  @Output() fieldStateEmitter = new EventEmitter<string>();

  @Input()
  set updateValueSet(newValue: string) {
    if (newValue) {
      this.labelTop = true;
      this.value = newValue;
    }
  }

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  constructor(
    // protected httpService: HttpService,
    private gmapUtilsService: GmapUtilsService
  ) {
    super();
  }

  handleAddressChange(address: Address): void {
    // this.value = this.searchInput.nativeElement.value;
    this.value = address.formatted_address;

    if (address.geometry) {
      this.addressSelected.emit(new GeoJsonIfc(
        this.value,
        address.formatted_address,
        address.place_id,
        address.geometry.location.lat(),
        address.geometry.location.lng(),
        this.gmapUtilsService.getCountry(address),
        this.gmapUtilsService.getState(address),
        this.gmapUtilsService.getCounty(address),
        this.gmapUtilsService.getCity(address),
        this.gmapUtilsService.getZip(address),
        this.gmapUtilsService.getStreetName(address),
        this.gmapUtilsService.getStreetNumber(address),
      ));
    } else {
      this.deleteAddress();
    }
  }

  modelChange(text: string): void {
    this.textChange.emit(text);
    if (this.value) {
      this.value = this.value.trim() + ' ';
    }
    if (!text) {
      this.addressDeleted.emit();
    }
  }

  focusIn(): void {
    this.active = true;
    this.labelTop = true;
    if (this.value) {
      this.value = this.value.trim() + ' ';
    }
  }

  focusOut(): void {
    this.fieldStateEmitter.emit(this.searchInput.nativeElement.value);
    this.active = false;
    if (this.value) {
      this.value = this.value.trim() + ' ';
    }
    this.onBlur();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  deleteAddress(): void {
    this.value = '';
    this.addressDeleted.emit();
  }
}
