import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  BatchClientDetail, GetPayerBatchRequestParams,
  PayerBatch,
  PayerBatchCreation,
  PayerBatchDetails,
  PayerBatchInvoice,
  PayerUnbatchVisit,
  SelectPayerUnbatchVisit
} from '@app/models/payer/payer-batches.model';
import { IdFullNameWidgetResponse, IdNameWidgetResponse, ResponseSuccess } from '@app/models/response.model';
import { ServiceWidgetWithUniqueSelector } from '@app/models/service.model';
import { OfficeWidget, PayerWidget } from '@app/models/widgets.model';
import { getUtcOffset } from '@app/common';

@Injectable({
  providedIn: 'root'
})
export class PayerBatchesService {

  constructor(
    private http: HttpService
  ) { }

  getPayerBatches(params: object): Observable<PaginatedResponse<PayerBatch>> {
    return this.http.GET(URLS.payer_batch, params);
  }

  getPayerBatchById(batchId: number): Observable<PayerBatchDetails> {
    return this.http.GET<PayerBatchDetails>(`${ URLS.payer_batch }${ batchId }/`);
  }

  getPayerBatchClientsById(params: object): Observable<PaginatedResponse<PayerBatchInvoice>> {
    return this.http.GET<PaginatedResponse<PayerBatchInvoice>>(URLS.payer_batch_item, params);
  }

  getPayerBatchOffices(batchId: number): Observable<IdNameWidgetResponse[]> {
    return this.http.GET<{ offices: IdNameWidgetResponse[] }>(`${ URLS.payer_batch }${ batchId }/report_selectors/`, {
      fields: 'offices'
    }).pipe(map((response) => response.offices));
  }

  getPayerBatchPatients(batchId: number): Observable<IdFullNameWidgetResponse[]> {
    return this.http.GET<{ patients: IdFullNameWidgetResponse[] }>(`${ URLS.payer_batch }${ batchId }/report_selectors/`, {
      fields: 'patients'
    }).pipe(map((response) => response.patients));
  }

  getPayerBatchPayers(batchId: number): Observable<IdNameWidgetResponse[]> {
    return this.http.GET<{ payers: IdNameWidgetResponse[] }>(`${ URLS.payer_batch }${ batchId }/report_selectors/`, {
      fields: 'payers'
    }).pipe(map((response) => response.payers));
  }

  getPayerBatchClientNestedDetails(clientId: number, batchId: number): Observable<BatchClientDetail> {
    return this.http.GET<BatchClientDetail>(`${ URLS.payer_batch_item }${ clientId }/?batch_id=${ batchId }`);
  }

  runPayerBatchClient(payload: { batch: number, batch_items: number[] }): Observable<ResponseSuccess> {
    return <Observable<ResponseSuccess>> this.http
    .POST(`${ URLS.payer_batch }run_batch/` ,payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayerUnbatchList(params: object): Observable<PaginatedResponse<PayerUnbatchVisit>> {
    return this.http.GET<PaginatedResponse<PayerUnbatchVisit>>(`${ URLS.payer_batch }not_batch_visits/`, {
      ...(params ?? {}),
      utc_offset: getUtcOffset()
    });
  }

  getSelectPayerUnbatchVisits(params: GetPayerBatchRequestParams): Observable<SelectPayerUnbatchVisit[]> {
    return this.http.GET(URLS.payer_batch, params);
  }

  addPayerBatchToVisit(visitId: number, payload): Observable<ResponseSuccess> {
    return <Observable<ResponseSuccess>> this.http
    .PUT(`${ URLS.payer_batch }${ visitId }/add_visits/`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deletePayerBatchClientVisit(visitId: number, batchId: number, payload): Observable<ResponseSuccess> {
    return <Observable<ResponseSuccess>> this.http
    .PUT(`${ URLS.payer_batch_item }${ visitId }/remove_visits/?batch_id=${ batchId }`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  createPayerBatch(body: PayerBatchCreation): Observable<PayerBatchCreation> {
    return <Observable<PayerBatchCreation>> this.http
    .POST(URLS.payer_batch, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  editPayerBatch(id: number, body: PayerBatchCreation): Observable<PayerBatchCreation> {
    return <Observable<PayerBatchCreation>>this.http
    .PUT(`${ URLS.payer_batch }${ id }/`, body)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getUserBatchesWidgetOffices(): Observable<PaginatedResponse<OfficeWidget>> {
    return <Observable<PaginatedResponse<OfficeWidget>>>this.http
    .GET(`${ URLS.office }?widget=m2m&user_offices=yes`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayers(): Observable<PaginatedResponse<PayerWidget>> {
    return <Observable<PaginatedResponse<PayerWidget>>>this.http
    .GET(`${ URLS.payer }?widget=m2m&limit=1000`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayerServices(params: { payers: number[] }): Observable<ServiceWidgetWithUniqueSelector[]> {
    return this.http.GET<ServiceWidgetWithUniqueSelector[]>(`${ URLS.payer_pricing_discipline }unique_selector_dataset/`, params);
  }
}
