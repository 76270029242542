import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideFilterPanelComponent } from './side-filter-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SideFilterPanelComponent
  ],
  exports: [
    SideFilterPanelComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule
    ]
})
export class SideFilterPanelModule { }
