
<div class="search-wrapper">
  <button class="app-button app-button_blue button-filter" (click)="openFilterChange(!isFilterOpen)">
    <span>{{ 'advancedFilters.title' | translate }}</span>
    <span class="material-icons-outlined">tune</span>
    <span *ngIf="filterItems?.length" class="counter">
           {{ filterItems.length }}
    </span>
  </button>
  <app-search-for-filter
    [filters]="filterItems"
    [searchText]="searchText"
    (openFilters)="isFilterOpen = $event"
    (deleteFilterItem)="onDeleteFilterItem($event)"
    (searchTextChanged)="searchTextChange($event)"
    [placeholder]="'placeholders.search' | translate">
  </app-search-for-filter>
</div>
<app-side-filter-panel [filterForm]="filterForm" (filterItemsChanged)="assignFilterItems($event)" (changeFilter)="filterChange($event)" (openChange)="openFilterChange($event)" [open]="isFilterOpen">
  <ng-content></ng-content>
</app-side-filter-panel>
