import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

import { getFormattedStringByDate } from '@app/common';
import { dateFormatValidator } from '@app/shared/validators/date-time-field.validator';
import { maxDateValidator } from '@app/shared/validators/max-date.validator';
import { minDateValidator } from '@app/shared/validators/min-date.validator';
import { DEFAULT_DATE_FORMAT } from '@app/shared/fields/datepicker-field/date-format';


@Component({
  selector: 'app-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['./datepicker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useFactory: (component: DatepickerFieldComponent) => {
        return [
          dateFormatValidator(DEFAULT_DATE_FORMAT),
          (control: AbstractControl) => minDateValidator(component?.minDate)(control),
          (control: AbstractControl) => maxDateValidator(component?.maxDate)(control),
        ];
      },
      deps: [forwardRef(() => DatepickerFieldComponent)]
    }
  ],
})
export class DatepickerFieldComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() clearable = false;
  @Input() allowChangeTextInput: boolean = true;
  @Input() placeholder = DEFAULT_DATE_FORMAT;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() toggleTooltip: string;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() datesFilter: (d: Date | null) => boolean;

  @Output() dateChange = new EventEmitter<string>();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>(); // in case you don`t use form but need to get value on change

  inputValue: string;
  datepickerValue: Date | null;

  private onChangeCallback: any = () => null;
  private onTouchedCallback: any = () => null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  onSelectDateOnDatepicker(date: Date): void {
    this.writeValue(getFormattedStringByDate(date));
    this.onChangeCallback(this.inputValue);
    this.dateChange.emit(this.inputValue);
  }

  onInputValueChanged(inputValue: string): void {
    this.writeValue(inputValue === '' ? null : inputValue);
    this.onChangeCallback(this.inputValue);
    this.dateChange.emit(this.inputValue);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  onInputClick(datepicker: MatDatepicker<any>): void {
    if (!this.allowChangeTextInput) {
      datepicker.open();
    }
  }

  // Date should be in format 'MM/DD/YYYY'
  writeValue(date: string | Date): void {
    this.inputValue = date instanceof Date ? moment(date).format(DEFAULT_DATE_FORMAT) : date;

    const isDateFormatCorrect = moment(date, DEFAULT_DATE_FORMAT, true).isValid();
    this.datepickerValue = isDateFormatCorrect ? new Date(this.inputValue) : null;
    this.valueChanged.next(this.datepickerValue);

    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
