export interface History {
  id: number;
  change_type: HistoryChangeType;
  change_type_value: string;
  message: string;
  user_short_name: string;
  user_profile_type_value: string;
  change_date_time: string;
}

export enum HistoryChangeType {
  FieldChanged,
  StatusChanged,
  Added,
  Deleted,
  Assign,
  Blasting,
}