import { GeneralStatus } from '@app/core/enums';
import { StatusesMapper } from '@app/models/statuses-mapper.model';
import { UserStatus } from '@app/models/users/user.ifc';

export const USER_STATUSES_MAPPER: StatusesMapper = {
  [UserStatus.Active]: GeneralStatus.Active,
  [UserStatus.Rejected]: GeneralStatus.Rejected,
  [UserStatus.TerminatedInvoluntary]: GeneralStatus.TerminatedInvoluntary,
  [UserStatus.TerminatedVoluntary]: GeneralStatus.TerminatedVoluntary,
  [UserStatus.Applicant]: GeneralStatus.Applicant,
  [UserStatus.MedicalLeave]: GeneralStatus.MedicalLeave,
  [UserStatus.EligibleForRehire]: GeneralStatus.EligibleForRehire,
  [UserStatus.Inactive]: GeneralStatus.Inactive,
  [UserStatus.NotVerified]: GeneralStatus.Inactive
}
