import { Attribute, ChangeDetectionStrategy, Component, forwardRef, Input, Provider } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TranslateService } from '@ngx-translate/core';

import { EXTENDED_ALLOWED_MIME_TYPES } from '@app/shared/constants';
import { FileLike } from '@app/shared/components/upload-button/upload-button.constants';
import { isFileURL } from '@app/shared/helper';
import { maxFileSizeValidator } from '@app/shared/validators/maxFileSize';
import { maxFilesSizeValidator } from '@app/shared/validators/maxFilesSize';
import { downloadWithAnchor } from '@app/common';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UploadButtonComponent), multi: true
};

const CUSTOM_VALIDATORS: Provider = {
  provide: NG_VALIDATORS,
  useFactory: (component: UploadButtonComponent) => {
    return [
      (control: AbstractControl) => maxFileSizeValidator(component.maxFileSizeMB)(control),
      (control: AbstractControl) => maxFilesSizeValidator(component.maxFilesSizeMB)(control),
    ];
  },
  deps: [forwardRef(() => UploadButtonComponent)]
};

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_VALIDATORS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadButtonComponent implements ControlValueAccessor {
  @Input() title = this.translate.instant('common.upload');
  @Input() allowedMimeTypes = EXTENDED_ALLOWED_MIME_TYPES.join(',');
  @Input() truncateBy = 25;
  @Input() maxFileSizeMB = 5;
  @Input() maxFilesSizeMB = 20;
  @Input() disabled = false;

  files: FileLike[] = [];
  isMultiple = false;

  onChange = (value: FileLike[]) => {};
  onTouch = () => {};

  constructor(@Attribute('multiple') private multiple: boolean, private translate: TranslateService) {
    this.isMultiple = coerceBooleanProperty(multiple);
  }

  writeValue(files: FileLike[] | null): void {
    this.files = files ?? [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFileSelected(event: Event): void {
    const newFiles = (event.target as HTMLInputElement).files;
    this.files = this.files.concat(Array.from(newFiles));
    this.onChange(this.files);
  }

  removeFile(file: FileLike, fileInput: HTMLInputElement): void {
    fileInput.value = ''; // to be able to select the same file again
    this.files = this.files.filter(f => f !== file);
    this.onChange(this.files.length === 0 ? null : this.files);
  }

  openFile(file: FileLike): void {
    let url: string;
    if (isFileURL(file)) {
      url = file.url;
    }
    else {
      url = URL.createObjectURL(file);
      if (!file.type) {
        return downloadWithAnchor(url, file.name);
      }
    }

    const newTab = window.open(url, '_blank');
    newTab.focus();
  }
}
