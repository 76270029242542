export interface WsData<Payload = unknown> {
  event: WsEvent;
  payload: Payload;
}

export enum WsConnectionModule {
  dispatch_screen = 'dispatch_screen',
  patients = 'patients',
  caregivers = 'caregivers',
  dispatch_screen_search_caregivers = 'dispatch_screen_search_caregivers',
  patients_assign_blasting = 'patients_assign_blasting',
  visit_details = 'dvi',
  commons = 'commons',
  active_blasting = 'active_blasting',
  main_batches = 'billing_main_batch',
  scheduling = 'scheduling',

  // Message Center
  chat = 'chat',
  listOfChats = 'list_of_chats',
  notifications = 'notifications',
}

export enum WsEvent {
  visit = 'visit',

  // NOTIFICATIONS
  notifications = 'notifications',
  read_notifications = 'read_notifications',

  // DVI
  dvi_header_info = 'dvi_header_info',
  dvi_schedule_tab = 'dvi_schedule',
  dvi_visit_tab = 'dvi_visit_info',

  // MASTER WEEK
  master_week_blasting_update = 'master_week_blasting',
  master_week_blasting_create = 'add_master_week_blasting',
  master_week_blasting_offer_update = 'master_week_blasting_offer',
  master_week_blasting_offer_create = 'add_master_week_blasting_offer',
  master_week_assign_blasting = 'master_week_assign_blasting',
  master_week_assigned = 'master_week_assigned',

  visit_blasting_update = 'visit_blasting',
  visit_blasting_create = 'add_visit_blasting',
  visit_blasting_offer_update = 'visit_blasting_offer',
  visit_blasting_offer_create = 'add_visit_blasting_offer',
  visit_blasting_attentions = 'visit_blasting_attentions',

  // COMMONS
  active_blasting_attentions = 'active_blasting_attentions',
  active_patient_blasting_attentions = 'active_patient_blasting_attentions',
  available_master_weeks_attentions = 'available_master_weeks_attentions',
  patient_authorization_blasting = 'patient_authorization_blasting',

  // CONNECTION REQUEST
  connection_request = 'connection_request',

  // MAIN BATCH
  main_batches_add_batch = 'add_billing_main_batch',
  main_batches_delete_batch = 'delete_billing_main_batch',
  main_batches_update_batch = 'updates_billing_main_batch',
  main_batches_update_batch_info = 'updates_billing_main_batch_info',

  main_batches_add_client = 'add_billing_main_batch_item',
  main_batches_delete_client = 'delete_billing_main_batch_item',
  main_batches_update_client = 'updates_billing_main_batch_item',
  main_batches_update_claim_info = 'updates_billing_main_batch_item_info',

  main_batches_add_claim_row = 'add_billing_main_batch_item_invoice',
  main_batches_delete_claim_row = 'delete_billing_main_batch_item_invoice',
  main_batches_update_claim_row = 'updates_billing_main_batch_item_invoice',

  // SCHEDULING
  scheduled_visit = 'scheduled_visit',
  patient_state = 'patient_state',

  // CAREGIVER CALENDAR
  caregiver_calendar = 'caregiver_calendar',
  remove_caregiver_calendar_visit = 'remove_caregiver_calendar_visit',
  add_caregiver_calendar_visit = 'add_caregiver_calendar_visit',

  // MESSAGE CENTER
  userStatusUpdating = 'user_status_updating',
  newChat = 'new_chat',
  chatUpdating = 'chat_updating',
  delete_chat = 'delete_chat',
  chatListUpdating = 'chat_list_updating',
  deleteChat = 'delete_chat',
  messageStatusUpdating = 'message_status_updating',
  newMessage = 'new_message',
  messageUpdating = 'message_updating',
  messageRepliesInfoUpdating = 'message_replies_info_updating',
  deleteMessage = 'delete_message',
  userViewedMessage = 'user_viewed_message',
  userUpdating = 'user_updating',
  user_typing_start = 'user_typing_start',
  user_typing_end = 'user_typing_end',

  // Custom internal events generated by frontend
  fe_internal_visit_status_update = 'fe_internal_visit_status_update',
  fe_internal_payer_update = 'fe_internal_payer_update',
  fe_internal_invoice_claim_submit = 'fe_internal_invoice_claim_submit',
}

export enum WsAction {
  init = 'init',
  user_typing_start = 'user_typing_start',
  user_typing_end = 'user_typing_end',
  userViewedMessage = 'user_viewed_message'
}
