import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterItem } from '@app/shared/interfaces/filter-item.ifc';

@Component({
  selector: 'app-search-for-filter',
  templateUrl: './search-for-filter.component.html',
  styleUrls: ['./search-for-filter.component.scss']
})
export class SearchForFilterComponent {
  private searchTimeout: any;

  @Input() searchText: string = '';
  @Input() placeholder: string = 'placeholders.search';
  @Input() timeout: number = 500;
  @Input() filters: FilterItem[] = [];

  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() deleteFilterItem = new EventEmitter<FilterItem>();
  @Output() openFilters = new EventEmitter<boolean>();

  readonly amountFilterItemsToShow = 2;

  searchInputChange(): void {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.searchTextChanged.emit(this.searchText);
    }, this.timeout);
  }

  onDeleteFilterItem(filterItem: FilterItem): void {
    this.deleteFilterItem.emit(filterItem);
  }

  public isArray(arr: any[]): boolean {
    return Array.isArray(arr);
  }
}
