import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { SERVER_CONTROL_ERROR_KEY } from '@app/shared/fields/errors/control-errors.component';
import { flattenObject } from '@app/shared/helper';
import { NotificationsService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private notificationService: NotificationsService,
  ) { }

  errorAsString(value: string, key?: string): void {
    let msg = '';
    if (key) {
      msg += key + ': ';
    }
    msg += value;
    if (key === 'non_field_errors') {
      msg = value;
    }

    this.notificationService.showError(msg);
  }

  errorAsArray(errors: Array<any>, key?: string) {
    errors.forEach((error) => {
      if (typeof error === 'string') {
        this.errorAsString(error, key);
      } else if (error instanceof Array) {
        this.errorAsArray(error, key);
      } else if (typeof error === 'object') {
        this.errorAsObject(error, key);
      }
    });
  }

  errorAsObject(errors: object, key?: string) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (typeof errors[key] === 'string') {
          this.errorAsString(errors[key], key);
        } else if (errors[key] instanceof Array) {
          this.errorAsArray(errors[key], key);
        } else if (typeof errors[key] === 'object') {
          this.errorAsObject(errors[key], key);
        }
      }
    }
  }

  nonFieldErrors(errors: HttpErrorResponse) {
    if (!errors.status || errors.status === 500) {
      this.notificationService.showError(errors);
      return;
    }
    if (typeof errors.error === 'string') {
      this.errorAsString(errors.error);
    } else if (errors.error instanceof Array) {
      this.errorAsArray(errors.error);
    } else if (typeof errors.error === 'object') {
      this.errorAsObject(errors.error);
    } else {
      this.notificationService.showError(errors);
    }
  }

  validateForm(form: FormGroup): void {
    for (const control of Object.values(form.controls ?? {})) {
      if (control instanceof FormGroup) {
        this.validateForm(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((formGroup: FormGroup) => this.validateForm(formGroup));
      } else {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }

    form.updateValueAndValidity();
  }

  setResponseErrorsToForm(errorResponse: HttpErrorResponse, formGroup: FormGroup): void {
    if (!errorResponse?.error) {
      return;
    }

    const flatErrors = flattenObject(errorResponse.error);

    Object.keys(flatErrors).forEach(key => {
      const pathToControl = key.substring(0, key.lastIndexOf('.'));
      const control: AbstractControl = formGroup.get(pathToControl);

      if (control && control instanceof FormControl) {
        control.addValidators(responseErrorValidator(control.value, flatErrors[key]));
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  }
}

function responseErrorValidator(controlValueWhenRequestWasSent: any, responseErrorMessage: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value === controlValueWhenRequestWasSent) {
      return { [SERVER_CONTROL_ERROR_KEY]: responseErrorMessage };
    }

    return null;
  };
}
