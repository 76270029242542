import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  AvailabilityDaySchedule,
  AvailabilityTimeSlot,
  AvailabilityWeekDay
} from '@app/models/users/caregiver-availability.model';
import { convertTimeToMinutesDuration } from '@app/shared/helper';

interface ValidatedTimeSlot extends AvailabilityTimeSlot {
  invalidRange?: boolean;
  collapsingWithOtherSlots?: boolean;
}

export interface DayScheduleRow extends AvailabilityDaySchedule {
  time_slots: ValidatedTimeSlot[];
}

const DEFAULT_TIME_FROM = '12:00 AM';
const DEFAULT_TIME_TO = '11:59 PM';

const DayNames = {
  [AvailabilityWeekDay.Sunday]: 'fullDaysOfWeek.sunday',
  [AvailabilityWeekDay.Monday]: 'fullDaysOfWeek.monday',
  [AvailabilityWeekDay.Tuesday]: 'fullDaysOfWeek.tuesday',
  [AvailabilityWeekDay.Wednesday]: 'fullDaysOfWeek.wednesday',
  [AvailabilityWeekDay.Thursday]: 'fullDaysOfWeek.thursday',
  [AvailabilityWeekDay.Friday]: 'fullDaysOfWeek.friday',
  [AvailabilityWeekDay.Saturday]: 'fullDaysOfWeek.saturday',
};

@Component({
  selector: 'app-availability-table',
  templateUrl: './availability-table.component.html',
  styleUrls: ['./availability-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailabilityTableComponent implements OnInit {
  @Input() schedule: AvailabilityDaySchedule[];

  @Output() valueChanged: EventEmitter<DayScheduleRow[]> = new EventEmitter<DayScheduleRow[]>();

  readonly displayedColumns: string[] = ['day', 'dateFrom', 'dateTo'];
  readonly dayNames = DayNames;

  items: DayScheduleRow[];

  ngOnInit(): void {
    this.items = this.schedule.map(day => {
      return {
        ...day,
        time_slots: day.time_slots.length ? day.time_slots : [generateDefaultTimeSlot()],
      };
    }).sort((a, b) => a.week_day > b.week_day ? 1 : -1);
  }

  timeChange(event: string, row: DayScheduleRow, slotIndex: number, key: 'time_from' | 'time_to'): void {
    row.time_slots[slotIndex][key] = event;
    this.validateTimeSlots(row);
    this.emitValueChanged();
  }

  dayOffChange(row: DayScheduleRow): void {
    row.day_off = !row.day_off;
    this.emitValueChanged();
  }

  deleteTimeSlot(weekDay: AvailabilityWeekDay, index: number): void {
    const daySchedule = this.items.find(item => item.week_day === weekDay);
    daySchedule.time_slots.splice(index, 1);
    this.validateTimeSlots(daySchedule);
    this.emitValueChanged();
  }

  addTimeSlot(weekDay: AvailabilityWeekDay): void {
    const daySchedule = this.items.find(item => item.week_day === weekDay);
    daySchedule.time_slots.push(generateDefaultTimeSlot());
    this.validateTimeSlots(daySchedule);
    this.emitValueChanged();
  }

  private emitValueChanged(): void {
    if (this.isScheduleValid()) {
      const result = this.items
        .map((item) => {
          const isDayInvalidAndDisabled = item.day_off && item.time_slots.some(timeslot => !isTimeslotValid(timeslot));
          if (isDayInvalidAndDisabled) {  // if day invalid but disabled then remove time slots
            return { ...item, time_slots: [], };
          } else {
            return item;
          }
        })
        .map(item => {
          const timeslots = item.time_slots.map(validatedTimeslotIntoDTO);
          return {
            ...item,
            time_slots: timeslots.length > 1 ? timeslots : timeslots.filter(item => !isTimeslotDefault(item))
          };
        });
      this.valueChanged.emit(result);
    } else {
      this.valueChanged.emit([]);
    }
  }

  private isScheduleValid(): boolean {
    return this.items.every(d => d.day_off || d.time_slots.every(isTimeslotValid));
  }

  private validateTimeSlots(daySchedule: DayScheduleRow): void {
    daySchedule.time_slots.forEach((timeslot, slotIndex) => {
      timeslot.invalidRange = this.checkIsTimeSlotHasInvalidRange(timeslot);
      timeslot.collapsingWithOtherSlots = this.checkIsTimeSlotCollapsingWithOther(
        timeslot, daySchedule.time_slots.filter((_, index) => index !== slotIndex)
      );
    });
  }

  private checkIsTimeSlotHasInvalidRange(row: AvailabilityTimeSlot): boolean {
    const startDuration = convertTimeToMinutesDuration(row.time_from);
    const endDuration = convertTimeToMinutesDuration(row.time_to);
    const minutesInOneHour = 60;

    return startDuration >= endDuration || endDuration - startDuration < minutesInOneHour;
  }

  private checkIsTimeSlotCollapsingWithOther(timeslot: AvailabilityTimeSlot, other: AvailabilityTimeSlot[]): boolean {
    const startTime = convertTimeToMinutesDuration(timeslot.time_from);
    const endTime = convertTimeToMinutesDuration(timeslot.time_to);

    return (other ?? []).some(item => {
      const itemStartTime = convertTimeToMinutesDuration(item.time_from);
      const itemEndTime = convertTimeToMinutesDuration(item.time_to);

      return (startTime <= itemStartTime && endTime >= itemStartTime) || (itemStartTime <= startTime && itemEndTime >= startTime);
    });
  }
}

function generateDefaultTimeSlot(): ValidatedTimeSlot {
  return {
    time_from: DEFAULT_TIME_FROM,
    time_to: DEFAULT_TIME_TO,
    invalidRange: false,
    collapsingWithOtherSlots: false
  };
}

function isTimeslotValid(timeslot: ValidatedTimeSlot): boolean {
  return !timeslot.invalidRange && !timeslot.collapsingWithOtherSlots;
}

function validatedTimeslotIntoDTO(timeslot: ValidatedTimeSlot): AvailabilityTimeSlot {
  const { collapsingWithOtherSlots, invalidRange, ...result } = timeslot;

  return result;
}

function isTimeslotDefault(timeslot: AvailabilityTimeSlot): boolean {
  return timeslot.time_to === DEFAULT_TIME_TO && timeslot.time_from === DEFAULT_TIME_FROM;
}
