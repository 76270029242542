import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AuthService, HttpService, RealtimeService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { GetUsersRequestParams, UserIfc } from '@app/models/users/user.ifc';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { EditUserData } from '@app/models/users/edit-user-data.model';
import { tap } from 'rxjs/operators';
import { WsEvent } from '@app/core/services/websocket/ws.models';
import { PaginationParams } from '@app/shared/interfaces/pagination.class';
import { History } from '@app/models/history.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  updateUserProfile$: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpService,
    private authService: AuthService,
    private realtimeService: RealtimeService,
  ) { }

  getUserById(userId: string): Observable<EditUserData> {
    return this.http.GET<EditUserData>(`${ URLS.profile }${ userId }/`);
  }

  getHrUserById(userId: string): Observable<UserIfc> {
    return this.http.GET<UserIfc>(`${ URLS.hr_profile }${ userId }/`);
  }

  getUsers(params: GetUsersRequestParams): Observable<PaginatedResponse<UserIfc>> {
    return this.http.GET<PaginatedResponse<UserIfc>>(URLS.profile, params);
  }

  updateUser(userId: number, payload): Observable<EditUserData> {
    return this.http.PUT<EditUserData>(`${ URLS.profile }${ userId }/`, payload).pipe(
      tap((response) => this.handleUserUpdated(response)),
    );
  }

  createUser(user: any): Observable<any> {
    return this.http.POST(URLS.profile, user);
  }

  getUserHistory(userId: number, params: PaginationParams): Observable<PaginatedResponse<History>> {
    return this.http.GET<PaginatedResponse<History>>(`${ URLS.history }user/${ userId }/`, params);
  }

  disconnectBaseUser(id: number): Observable<void> {
    return this.http.POST<void>(`${ URLS.profile }${ id }/disconnect_base_user/`);
  }

  handleUserUpdated(user: EditUserData): void {
    this.realtimeService.emitInternalEvent({ event: WsEvent.userUpdating, payload: user });
    if (this.authService.profile.id === user.id) {
      this.authService.updateProfile({
        fullName: user.full_name,
        avatar: user.avatar
      });
      this.updateUserProfile$.next();
    }
  }
}
