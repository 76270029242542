<div
  class="app-timepicker"
  [class.disabled]="disabled"
  [class.active]="isPeriodSelectionOpened">

  <app-number-field
    placeholder="_"
    [(ngModel)]="hour"
    [minDigitsAfterDot]="0"
    [maxDigitsAfterDot]="0"
    [max]="12"
    [min]="1"
    [disabled]="disabled"
    (ngModelChange)="onTimeChanged()"
    (blur)="onTouched()"
  ></app-number-field>

  <span class="app-timepicker__separator">:</span>

  <app-number-field
    placeholder="_"
    [(ngModel)]="minute"
    [minDigitsAfterDot]="0"
    [maxDigitsAfterDot]="0"
    [max]="59"
    [min]="0"
    [step]="15"
    [minDigitsBeforeDot]="2"
    [disabled]="disabled"
    (ngModelChange)="onTimeChanged()"
    (blur)="onTouched()"
  ></app-number-field>

  <mat-select
    tabindex="0"
    class="app-timepicker__select"
    panelClass="app-timepicker-select-panel"
    [disabled]="disabled"
    [(ngModel)]="period"
    (openedChange)="onPeriodPanelToggle($event)"
    (ngModelChange)="changeTimePeriod(period)">
    <mat-option *ngFor="let option of periodOptions" [value]="option.value">{{ option.label }}</mat-option>
  </mat-select>
</div>
