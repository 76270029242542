import { Observable, Subject } from 'rxjs';

import { QueryBuilder } from '@app/core/query-builder';
import { OptionsMultiselectEvent } from '@app/shared/fields/multiselect-field/models';

export class FiltrationParams<T = any> {
  paramsChange: Observable<T>;

  private paramsChangeSubject: Subject<T> = new Subject<T>();

  constructor(private params: T) {
    this.paramsChange = this.paramsChangeSubject.asObservable();
  }

  getParams(): T {
    return { ...this.params };
  }

  setParams(params: T, emitEvent = true) {
    this.changeParams(params, emitEvent);
  }

  patchParams(params: Partial<T>, emitEvent = true) {
    this.changeParams({ ...this.params, ...params }, emitEvent);
  }

  deleteParamKey(key: keyof T, emitEvent = true): void {
   const newParams = this.getParams();
   delete newParams[key];

   this.changeParams(newParams, emitEvent);
  }

  handleMultiselectEvent(event: OptionsMultiselectEvent<T>, key: keyof T, emitEvent = true): void {
    if (event.isSelectedAll) {
      this.deleteParamKey(key, emitEvent);
    } else {
      this.patchParams({ [key]: event.newValues } as T, emitEvent);
    }
  }

  // Hardcoded search and lookup_field properties because they are used in all components with search
  // P.S. Do not encode since it is already encoded in ParametricSearchComponent
  handleSearchEvent(newText: string): void {
    newText ? this.patchParams({ search: newText } as T) : this.deleteParamKey('search' as keyof T);
  }

  handleSearchOptionEvent(newOption: string): void {
    this.patchParams({ lookup_field: newOption } as T, !!this.params['search']);
  }

  private changeParams(params: T, emitEvent: boolean): void {
    const isParamsChanged = !QueryBuilder.isParamsSame(this.params, params);
    this.params = params;

    if (emitEvent && isParamsChanged) {
      this.paramsChangeSubject.next(this.getParams());
    }
  }
}
