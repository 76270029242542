/* tslint:disable */
import { LanguageIfc } from '@app/models/language.ifc';
import { EmergencyContactsInfo } from '@app/shared/interfaces/eci.ifc';
import { FullAddressIfc } from '@app/shared/interfaces/full-address.ifc';
import { AnimalIfc, OfficeIfc, ServiceIfc } from '@app/shared/interfaces/mtm.ifc';
import { UserPermissions } from './user-permissions.model';
import { Phone } from "@app/models/phone.model";
import { PaginationParams } from "@app/shared/interfaces/pagination.class";
import { UserType } from "@app/models/users/user-profile.model";
import { RaceType } from '@app/core/enums';
import { GenderType } from '../gender.model';
import { MaritalStatus } from './edit-user-data.model';

export enum UserStatus {
  Active,
  Rejected,
  TerminatedInvoluntary,
  TerminatedVoluntary,
  Applicant,
  MedicalLeave,
  EligibleForRehire,
  Inactive,
  NotVerified
}

export class UserProfileIfc {
  id: number;
  ssn: string;
  birthday: string;
  gender: number;
  gender_value: string;
  smoking: boolean;
  employment_type: number | null;
  employment_type_detail: ServiceIfc | any;
  notes: string;
  referral_source: string;
  referral_person: string;
  application_date: string;
  hha_pca: string;
  licence: string;
  npi: string;
  contact_method: number;
  contact_method_value: string;
  trainee_status: number;
  trainee_status_value: string;
  primary_language: number;
  primary_language_detail: LanguageIfc;
  secondary_language: number;
  secondary_language_detail: LanguageIfc;
  animals: [];
  animals_details: AnimalIfc[];

  constructor(newProfile) {
    this.id = newProfile.id || null;
    this.ssn = newProfile.ssn || '';
    this.birthday = newProfile.birthday || '';
    this.gender = newProfile.gender || 0;
    this.gender_value = newProfile.gender_value || '';
    this.smoking = newProfile.smoking || false;
    this.employment_type = newProfile.employment_type || null;
    this.employment_type_detail = newProfile.employment_type_detail || {};
    this.notes = newProfile.notes || '';
    this.referral_source = newProfile.referral_source || '';
    this.referral_person = newProfile.referral_person || '';
    this.application_date = newProfile.application_date || null;
    this.hha_pca = newProfile.hha_pca || '';
    this.licence = newProfile.licence || '';
    this.npi = newProfile.npi || '';
    this.contact_method = newProfile.contact_method || 0;
    this.contact_method_value = newProfile.contact_method_value || '';
    this.trainee_status = newProfile.trainee_status || 0;
    this.trainee_status_value = newProfile.trainee_status_value || '';
    this.primary_language = newProfile.primary_language;
    this.primary_language_detail = newProfile.primary_language_detail;
    this.secondary_language = newProfile.secondary_language;
    this.secondary_language_detail = newProfile.secondary_language_detail;
    this.animals = newProfile.animals || [];
    this.animals_details = newProfile.animals_details || [];
  }
}

export class UserIfc {
  id: number;
  base_user: number;
  has_connected_base_user: boolean;
  number: number;
  avatar: string;
  email: string;
  status: number;
  status_value: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  birthday: string;
  ssn: string;
  ethnicity: number;
  ethnicity_value: string;
  is_active: boolean;
  is_online: boolean;

  address_data: FullAddressIfc;
  address_detail: FullAddressIfc;

  phone_number: string;
  phone_numbers: Phone[];
  profile: UserProfileIfc;
  profile_type: number;
  profile_type_value: string;
  username: string;
  uuid: string;
  user_offices: number[];
  user_offices_details: OfficeIfc[];
  emergency_contacts_info: Array<EmergencyContactsInfo>;
  race: number;
  race_value: string;

  isAdminisitrator: boolean;
  isCoordinator: boolean;
  isPayroll: boolean;
  isBiller: boolean;
  isCaregiver: boolean;
  isTrainee: boolean;

  features: { homecare: boolean, hr: boolean };
  permissions: UserPermissions;

  facebook_link: string;
  linked_in_link: string;
  twitter_link: string;

  has_new_applications: boolean;
  has_new_messages: boolean;
  has_external_source: boolean;
  checked: boolean;

  constructor(newUser: any = {}) {
    this.id = newUser.id || null;
    this.number = newUser.number ?? '';
    this.avatar = newUser.avatar || null;
    this.birthday = newUser.birthday || '';
    this.status = newUser.status || 0;
    this.status_value = newUser.status_value || '';
    this.first_name = newUser.first_name || '';
    this.middle_name = newUser.middle_name || '';
    this.last_name = newUser.last_name || '';
    this.full_name = newUser.full_name || '';

    this.base_user = newUser.base_user || null,
    this.has_connected_base_user = newUser.has_connected_base_user || false,

    this.is_active = newUser.is_active !== undefined ? newUser.is_active : null;
    this.is_online = newUser.is_online !== undefined ? newUser.is_online : null;

    this.ethnicity = newUser.ethnicity || null;
    this.ethnicity_value = newUser.ethnicity_value || null;
    this.race = newUser.race || null;
    this.race_value = newUser.race_value || null;

    this.profile = new UserProfileIfc(newUser.profile ? newUser.profile : {});
    this.profile_type = newUser.profile_type;
    this.profile_type_value = newUser.profile_type_value || 0;
    this.username = newUser.username || '';
    this.uuid = newUser.uuid || '';
    this.user_offices = [];
    this.user_offices_details = newUser.user_offices_details || [];

    this.email = newUser.email || '';
    this.phone_number = newUser.phone_number;
    this.phone_numbers = newUser.phone_numbers;

    this.address_data = new FullAddressIfc(newUser.address_detail || {});
    this.address_detail = new FullAddressIfc(newUser.address_detail || {});

    this.emergency_contacts_info = [];
    if (newUser.emergency_contacts_info) {
      this.emergency_contacts_info = newUser.emergency_contacts_info.map((item) => new EmergencyContactsInfo(item));
    }

    this.isAdminisitrator = this.profile_type === 0;
    this.isCoordinator = this.profile_type === 1;
    this.isPayroll = this.profile_type === 2;
    this.isBiller = this.profile_type === 3;
    this.isCaregiver = this.profile_type === 4;
    this.isTrainee = this.profile_type === 5;

    this.features = newUser.features || {};
    this.permissions = newUser.permissions || {};

    this.facebook_link = newUser.facebook_link || '';
    this.linked_in_link = newUser.linked_in_link || '';
    this.twitter_link = newUser.twitter_link || '';

    this.has_new_applications = newUser.has_new_applications || false;
    this.has_new_messages = newUser.has_new_messages || false;
    this.has_external_source = newUser.has_external_source || false;
    this.checked = false;
  }
}

export interface GetUsersRequestParams extends PaginationParams {
  lookup_field?: string;
  search?: string;
  races?: RaceType[];
  marital_statuses?: MaritalStatus[];
  offices?: number[];
  employment_types?: number[];
  coordinators?: number[];
  primary_languages?: number[];
  genders?: GenderType[];
  working_with_pets?: number[];
  smoking?: boolean[];
  profile_types?: UserType | UserType[];
  statuses?: UserStatus | UserStatus[];
  profile_widget?: string;
  nurse_only?: string;
  widget?: string;
  no_limits?: boolean | string;
}
