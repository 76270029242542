<div class="ampm">
  <div class="ampm__field">
    <ng-container *ngIf="!isShowForm && initialRange">
      <span
        class="ampm__input-value underline-text"
        (click)="showForm()"
      >{{ hoursInputStart }}<span class="ampm__colon">:</span>{{ minutesInputStart }} {{ ampmInputStart }}</span>
      <span class="ampm__hyphen">&mdash;</span>
      <span
        class="ampm__input-value underline-text"
        (click)="showForm()"
      >{{ hoursInputEnd }}<span class="ampm__colon">:</span>{{ minutesInputEnd }} {{ ampmInputEnd }}</span>
    </ng-container>

    <ng-container *ngIf="isShowForm">
      <form [formGroup]="form" class="ampm__form" [class.invalid]="isIncorrectTimesDifference">
        <input
          class="ampm__input"
          (input)="hoursChanged($event, hoursStart)"
          [ngClass]="{ 'has-error': (hoursStart.touched || hoursStart.dirty) && hoursStart.invalid }"
          placeholder="HH"
          maxlength="2"
          formControlName="hoursStart"
          type="text"/>
        <span class="ampm__colon">:</span>
        <input
          class="ampm__input"
          (input)="minutesChanged($event, minutesStart)"
          [ngClass]="{ 'has-error': (minutesStart.touched || minutesStart.dirty) && minutesStart.invalid }"
          placeholder="MM"
          maxlength="2"
          formControlName="minutesStart"
          type="text"/>
        &nbsp;&nbsp;
        <ng-select
          class="app-ng-select"
          bindLabel="label"
          bindValue="value"
          appendTo="body"
          appearance="outline"
          formControlName="ampmStart"
          [items]="amPMList"
          [clearable]="false"
          [searchable]="false"
          (change)="ampmChanged()">
        </ng-select>

        <span class="ampm__hyphen">&mdash;</span>

        <input
          class="ampm__input"
          (input)="hoursChanged($event, hoursEnd)"
          [ngClass]="{ 'has-error': (hoursEnd.touched || hoursEnd.dirty) && hoursEnd.invalid }"
          placeholder="HH"
          maxlength="2"
          formControlName="hoursEnd"
          type="text"/>
        <span class="ampm__colon">:</span>
        <input
          class="ampm__input"
          (input)="minutesChanged($event, minutesEnd)"
          [ngClass]="{ 'has-error': (minutesEnd.touched || minutesEnd.dirty) && minutesEnd.invalid }"
          placeholder="MM"
          maxlength="2"
          formControlName="minutesEnd"
          type="text"/>
        &nbsp;&nbsp;
        <ng-select
          class="app-ng-select"
          bindLabel="label"
          bindValue="value"
          appendTo="body"
          appearance="outline"
          formControlName="ampmEnd"
          [items]="amPMList"
          [clearable]="false"
          [searchable]="false"
          (change)="ampmChanged()">
        </ng-select>

        <button
          matRipple
          class="ampm__btn"
          [disabled]="!form.valid"
          (click)="saveTime()"
          type="button">
          <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
        </button>
        <button matRipple class="ampm__btn" (click)="cancel()" type="button">
          <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
        </button>
      </form>

      <mat-error *ngIf="isIncorrectTimesDifference">
        {{ "commonErrors.date_endTimeIsEarlierThanStartTime" | translate }}
      </mat-error>
    </ng-container>
  </div>

  <button
    *ngIf="!disabled"
    matRipple
    [matTooltip]="editTooltip"
    class="ampm__edit-btn"
    [class.no-show]="isShowForm"
    (click)="showForm()">
    <mat-icon class="material-icons-two-tone icon-gray icon-edit">edit</mat-icon>
  </button>
</div>
