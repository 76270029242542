import { ChangeDetectorRef, inject, InjectionToken, Provider } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type HideLoader = () => void;

export const LoadingHandlerToken = new InjectionToken<LoadingHandler>('Loading Handler');

export function provideLoadingHandler(): Provider {
  return {
    provide: LoadingHandlerToken,
    useFactory: () => new LoadingHandler()
  };
}

export class LoadingHandler {
  private changeDetectorRefs: ChangeDetectorRef[] = [inject(ChangeDetectorRef)];
  private loadingProcesses = 0;

  loadingState = new BehaviorSubject(false);

  get loading(): boolean {
    return this.loadingState.value;
  }

  get initializing(): boolean {
    return this._initializing;
  }

  // tslint:disable-next-line:variable-name
  constructor(private _initializing = false) {
  }

  showLoading(initializing: boolean = false): HideLoader {
    this.loadingProcesses++;
    this.loadingState.next(true);
    this._initializing = initializing;
    this.markForCheck();

    return this.hideLoader.bind(this);
  }

  bindChangeDetectorRef(cdr: ChangeDetectorRef): void {
    this.changeDetectorRefs.push(cdr);
  }

  private hideLoader(): void {
    if (this.loadingProcesses <= 0) {
      return;
    }

    if (--this.loadingProcesses === 0) {
      if (this.loadingProcesses === 0) {
        this._initializing = false;
        this.loadingState.next(false);
        this.markForCheck();
      }
    }
  }

  private markForCheck(): void {
    this.changeDetectorRefs.forEach(cdr => cdr.markForCheck());
  }
}
