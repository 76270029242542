export interface User {
  id: number;
  short_name: string;
  status: OnlineStatus;
  status_value: string;
  was_last_online: string;
  role: number;
  role_value: string;
  avatar: string;
  meta?: Partial<UserMeta>;
}

export enum OnlineStatus {
  Online,
  Offline
}

interface UserMeta {
  avatar: string;
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  role: number;
  username: string;
}

export interface MCProfile {
  readonly id: number;
  email: string;
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  role: number;
  readonly role_value: string;
  status: number;
  readonly status_value: string;
  was_last_online: string;
  readonly avatar: string;
  permissions: any;
  checked?: boolean;
}

interface Company {
  readonly id: number;
  name: string;
  industry: number;
  industry_value: string;
}
