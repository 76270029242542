import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { ReportTemplateCreate, ReportTemplate } from '@app/models/reports/report-template.model';
import { ShareReportTemplate } from '@app/models/reports/share-report.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { ReportModelType, ReportType } from '@app/models/reports/reports.model';
import { DownloadReportPayload, DownloadReportResponse } from '@app/models/reports/download-report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private readonly storageConfigKey = 'hc.reports.config';

  constructor(private http: HttpService) { }

  getConfig(): ReportTemplateCreate {
    try {
      return JSON.parse(localStorage.getItem(this.storageConfigKey));
    } catch (err) {
      return null;
    }
  }

  setConfig(data: ReportTemplateCreate): void {
    localStorage.setItem(this.storageConfigKey, JSON.stringify(data));
  }

  getSavedTemplates(params: object): Observable<PaginatedResponse<ReportTemplate>> {
    return this.http.GET<PaginatedResponse<ReportTemplate>>(`${ URLS.report_tempate }`, params);
  }

  getSavedTemplateById(id: number): Observable<ReportTemplate> {
    return this.http.GET<ReportTemplate>(`${ URLS.report_tempate }${ id }/`);
  }

  createReportTemplate(data: ReportTemplateCreate): Observable<ReportTemplateCreate> {
    return this.http.POST<ReportTemplateCreate>(`${ URLS.report_tempate }`, data);
  }

  deleteSavedTemplate(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.report_tempate }${ id }/`);
  }

  generateReport(type: ReportType, query: object): Observable<ReportModelType | ReportModelType[]> {
    return this.http.GET<ReportModelType[]>(`${ URLS.report_tempate }generate_report/${ type }/`, query);
  }

  downloadReport(type: ReportType, data: DownloadReportPayload): Observable<DownloadReportResponse> {
    return this.http.POST<DownloadReportResponse>(`${ URLS.report_tempate }generate_report/${ type }/`, data);
  }

  shareReport(data: ShareReportTemplate): Observable<ShareReportTemplate> {
    return this.http.POST<ShareReportTemplate>(`${ URLS.report_tempate }share_report_template/`, data);
  }

  sendReportViaEmail(reportType: ReportType, data: ShareReportTemplate): Observable<ShareReportTemplate> {
    return this.http.POST<ShareReportTemplate>(`${ URLS.report_tempate }send_report_via_email/${ reportType }/`, data);
  }
}
