import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  CaregiverAvailability,
  CaregiverAvailabilityCalendar,
  CaregiverAvailabilityCalendarGetParams
} from '@app/models/users/caregiver-availability.model';
import { CaregiverService } from '@app/core/services';
import { ObservableCache } from '@app/core/cache';

@Injectable({
  providedIn: 'root'
})
export class CaregiverAvailabilityService {
  private cache = new ObservableCache<CaregiverAvailabilityCalendar[]>();

  refresh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  availability$: BehaviorSubject<CaregiverAvailability> = new BehaviorSubject<CaregiverAvailability>(null);

  constructor(
    private caregiverService: CaregiverService
  ) {
  }

  loadCalendar(id: number, params: CaregiverAvailabilityCalendarGetParams, ignoreCache: boolean = false): Observable<CaregiverAvailabilityCalendar[]> {
    const cacheKey = `${ id }-${ params.year }-${ params.month }`;

    if (ignoreCache) {
      return this.cache.set(cacheKey, this.caregiverService.getCalendar(id, params));
    } else {
      return this.cache.get(cacheKey, this.caregiverService.getCalendar(id, params));
    }
  }
}
