import { AbstractControl } from '@angular/forms';

export const MIN_DATE = new Date(1900, 0, 1);
export const MAX_DATE = new Date();

export const PLACEHOLDER_DATE = 'MM/DD/YYYY';
export const FORM_STATUS_VALID = 'VALID';

export function getAdultBirthday(): Date {
  const today = new Date();
  const adultYears = 18;

  return new Date(today.getFullYear() - adultYears, today.getMonth(), today.getDate());
}

export function getFormattedStringByDate(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${ month }/${ day }/${ year }`;
}

export const regexTime12 = /^((0[1-9])|(1[0-2])):([0-5])([0-9])$/;

export const onlyDigitalsRegExp = /\D/g;
export const clearUrlRegExp = /^[^?]+/;

export function getFormattedStringByPhone(phone: string): string {
  if (!phone || phone.length !== 10) {
    return '';
  }
  return `(${ phone.substring(0, 3) }) ${ phone.substring(3, 6) }-${ phone.substring(6, 10) }`;
}

export function setValueInControl(control: AbstractControl, value: any): void {
  control.setValue(value);
  control.markAsDirty();
}

export function getTomorrow(date: Date): Date {
  return new Date(new Date(date).setDate(date.getDate() + 1));
}

export function getUtcOffset(): number {
  return new Date().getTimezoneOffset();
}

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  if (!file) {
    resolve(null);
    return;
  }

  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => {
    let result = reader.result as string;
    const fileExtension = getFileExtension(file.name);
    const MIME_TYPE_REMAP: Record<string, string> = {
      rar: 'application/x-rar',
    };

    const mappedMimeType = MIME_TYPE_REMAP[fileExtension];
    if (mappedMimeType) {
      result = result.replace(/application\/octet-stream/, mappedMimeType);
    }
    resolve(result);
  };
  reader.onerror = error => reject(error);
});

export function getFileExtension(name: string): string {
  return name.match(/[.]\w+$/)[0].slice(1);
}

export function downloadWithAnchor(url: string, fileName = crypto.randomUUID()): void {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  URL.revokeObjectURL(url);
}

export function objectIsEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0;
}
