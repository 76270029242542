import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

const DECIMAL_PIPE = new DecimalPipe('en');

@Component({
  selector: 'app-number-field',
  templateUrl: 'number-field.component.html',
  styleUrls: ['number-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    }
  ]
})
export class NumberFieldComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() invalid = false;
  @Input() disabled = false;
  @Input() min: number = Number.NEGATIVE_INFINITY;
  @Input() max: number = Infinity;
  @Input() minDigitsBeforeDot = 1;
  @Input() minDigitsAfterDot = 2;
  @Input() maxDigitsAfterDot = 2;
  @Input() selectOnFocus = true;
  @Input() allowNegative = false;
  @Input() step: number = 1;

  inputText: string = '';

  private onChangeCallback: any = () => null;
  private onTouchedCallback: any = () => null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  writeValue(value: number | string): void {
    this.inputText = this.transformValue(value);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onInputChange(value: string, input: HTMLInputElement): void {
    if (value === '') {
      this.inputText = value;
      this.onChangeCallback(this.inputText);
      return;
    }

    if (this.valueToNumber(value) > this.max) {
      this.inputText = String(this.max);
      input.value = this.inputText;
    } else if (this.valueToNumber(value) < this.min) {
      this.inputText = String(this.min);
      input.value = this.inputText;
    } else if (this.isAllowedDigitsCountAfterDot(value)) {
      this.inputText = this.transformValue(value);
      input.value = this.inputText;
    } else {
      this.inputText = value;
    }

    this.onChangeCallback(this.valueToNumber(this.inputText));
  }

  onInputFocus(input: HTMLInputElement): void {
    if (this.selectOnFocus) {
      input.select();
    }
  }

  onInputBlur(): void {
    this.inputText = this.transformValue(this.inputText);
    this.onTouchedCallback();
  }

  stepUp(input: HTMLInputElement): void {
    const value = this.valueToNumber(this.inputText) + this.step;
    this.onInputChange(String(Math.floor(value / this.step) * this.step), input);
  }

  stepDown(input: HTMLInputElement): void {
    const value = this.valueToNumber(this.inputText) - this.step;
    this.onInputChange(String(Math.ceil(value / this.step) * this.step), input);
  }

  private isAllowedDigitsCountAfterDot(inputText: string): boolean {
    return inputText.split('.')[1]?.length > this.maxDigitsAfterDot;
  }

  private transformValue(value: string | number): string {
    if (value == null) {
      return '';
    }

    const format = `${ this.minDigitsBeforeDot }.${ this.minDigitsAfterDot }-${ this.maxDigitsAfterDot }`;

    return DECIMAL_PIPE.transform(this.valueToNumber(String(value)), format)?.replaceAll(',', '');
  }

  private valueToNumber(value: string): number {
    if (value === '') {
      return null;
    }

    return +value?.replaceAll(',', '');
  }
}
