<div class="search" [class.with-labels]="filters.length > 0">
  <div class="left-side">
    <div class="icon-search"><i class="fa fa-search"></i></div>
    <div class="value-wrapper">
      <input
        class="value"
        [placeholder]="placeholder | translate"
        [(ngModel)]="searchText"
        [matTooltip]="'parametricSearch.searchBy_tooltip' | translate"
        (keyup)="searchInputChange()"/>
      <mat-icon
        class="icon-delete"
        [hidden]="!searchText.length"
        (click)="searchText = ''; searchInputChange()">
        close
      </mat-icon>
    </div>
  </div>

  <div class="filter-labels" *ngIf="filters.length > 0">
    <ng-container *ngFor="let item of filters.slice(0, amountFilterItemsToShow)">
      <div class="item" (click)="openFilters.emit(true)">
      <span>
        {{ item.name | translate }}: {{ isArray(item.value) ? item.value.length : item.value }}
      </span>
        <mat-icon (click)="onDeleteFilterItem(item); $event.stopPropagation()">close</mat-icon>
      </div>
    </ng-container>

    <div class="item" *ngIf="filters.length > amountFilterItemsToShow" (click)="openFilters.emit(true)">
      <span>{{ 'and ' + (filters.length - amountFilterItemsToShow) + ' more' }}</span>
    </div>
  </div>
</div>
