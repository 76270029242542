import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { MessageCenterService } from './services/message-center.service';
import { CreateGroupComponent } from './create-group/create-group.component';
import { UserChat } from './models/chat.model';

@UntilDestroy()
@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent {
  constructor(
    private dialogRef: MatDialogRef<MessageCenterComponent>,
    private messageCenterService: MessageCenterService,
    private dialog: MatDialog,
  ) {
  }

  openChat(chat: UserChat): void {
    this.messageCenterService.openChat$.emit(chat);
  }

  closeAllChats(): void {
    this.messageCenterService.closeAllChats$.emit();
  }

  close(): void {
    this.dialogRef.close();
    this.messageCenterService.closeMessageCenter();
  }

  createNewGroup(): void {
    this.dialog.open(CreateGroupComponent, {
      width: '100%',
      maxWidth: '596px',
      maxHeight: '90vh',
      restoreFocus: false,
      autoFocus: false,
    })
    .afterClosed()
    .pipe(
      filter((res: UserChat) => !!res),
      untilDestroyed(this)
    ).subscribe((res: UserChat) => this.openChat(res));
  }
}
