<div class="group">
  <div class="group__header">
    <span class="group__title">
      <ng-container *ngIf="!data?.chatId; else editGroup">{{ 'messageCenter.createNewGroup' | translate }}</ng-container>
      <ng-template #editGroup>{{ 'buttons.edit' | translate }}</ng-template>
      {{ 'messageCenter.group' | translate }}
    </span>
    <button matRipple class="btn-close" (click)="close()">
      <mat-icon class="material-icons btn-close__icon">close</mat-icon>
    </button>
  </div>

  <app-loading *ngIf="isLoading" class="loading"></app-loading>

  <ng-container *ngIf="!isLoading">
    <form
    class="group__form form"
    novalidate
    [formGroup]="form"
    autocomplete="off">

    <div class="form__data">
      <app-file-upload
        type="field"
        fieldSize="87px"
        [urlImg]="avatar.value"
        [multiple]="false"
        (filesAdded)="addAvatar($event)"
      ></app-file-upload>
      <div class="form__inputs">
        <app-form-field label="Name*" fieldClasses="field-with-errors">
          <input
            class="mc-form-field-input"
            type="text"
            appTrimInput
            placeholder="Group Name"
            formControlName="name"
            #nameControl="ngControl"
            [class.error]="nameControl.touched && nameControl.invalid"/>
          <div class="error-field">
            <app-errors [field]="nameControl"></app-errors>
          </div>
        </app-form-field>

        <app-form-field label="Type*">
          <ng-select
            class="app-ng-select"
            #typeControl="ngControl"
            appearance="outline"
            formControlName="type"
            bindValue="value"
            [searchable]="false"
            [clearable]="false"
            [placeholder]="'placeholders.select' | translate"
            [items]="groupsTypes">
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              {{ item.title | translate }}
            </ng-template>
          </ng-select>
        </app-form-field>
      </div>
    </div>

    <app-form-field
      *ngIf="typeControl.value && typeControl.value !== GroupType.Public"
      [label]="'messageCenter.label_addUsers' | translate">
      <div class="added-users-list">
        <app-select-chip-input
          ngDefaultControl
          [allChips]="allChipsUsers"
          [selectedChips]="selectedChipsUsers"
          (selectedChip)="addChipsUser($event)"
          (valueChanged)="searchUsers($event)"
          (removedChip)="removeChip($event)"
          (blurEmitter)="loadUsers()"
        ></app-select-chip-input>
      </div>
    </app-form-field>
  </form>

  <div class="group__buttons">
    <button class="mc-button mc-button_red" matRipple (click)="close()">{{ 'buttons.cancel' | translate }}</button>
    <button
      class="mc-button mc-button_blue"
      matRipple
      [disabled]="isInvalid()"
      (click)="createGroup()">
      <span *ngIf="!isSending" class="material-icons-two-tone btn-icon">group_add</span>
      <mat-spinner
        *ngIf="isSending"
        class="spinner"
        diameter="16"
        strokeWidth="2"
      ></mat-spinner>
      <ng-container *ngIf="!data?.chatId; else editMode">{{ 'buttons.create' | translate }}</ng-container>
      <ng-template #editMode>{{ 'buttons.edit' | translate }}</ng-template>
    </button>
  </div>
  </ng-container>
</div>
